import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import ReorderIcon from "@mui/icons-material/Reorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilledButton from "../../Button/FilledButton";
import OutlinedButton from "../../Button/OutlinedButton";
import DeviceDetector from "device-detector-js";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  UpdateTemplateDataSpecific,
  UpdateTemplateDataSpecificWithLoader,
  UpdateTemplateMediaDataSpecific,
} from "../../../Helpers/ApiCall";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { useDispatch } from "react-redux";
import PdfReorderMedia from "./PdfReorderMedia";
import update from "immutability-helper";
import DeleteConfirmationDialog from "../../../Common/Alert/DeleteConfirmationDialog";
import { filterObjectsUptoTemplate } from "../../../Helpers/HelperFunctions";

function EditPdfMedia({
  setShowPdfEditModal,
  showPdfEditModal,
  element,
  handleAddMedia,
  mediaData,
  templateId,
  setLoader,
  editMediaInput,
}) {
  const [inspectionData, setInspectionData] = useState();
  const [reorderMediaData, setReorderMediaData] = useState([]);
  const [afterReodermediaData, setAfterReorderMediaData] = useState([]);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [captionChanged, setCaptionChanged] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [inspectionResponse, setInspectionResponse] = useState();
  const [deleteMedia, setDeleteMedia] = useState(false);
  const [reOrderMedia, setReOrderMedia] = useState(false);
  const [image, setImage] = useState("");
  const [currentMedia, setCurrentMedia] = useState();
  const [activeDeleteMedia, setActiveDeleteMedia] = useState({});
  const [caption, setCaption] = useState();
  const storeData = useSelector((state) => state.CommonReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const deviceDetector = new DeviceDetector();
  const userAgent =
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
  const device = deviceDetector.parse(userAgent);
  const dispatch = useDispatch();
  useEffect(() => {
    setReorderMediaData(mediaData);
  }, [mediaData]);

  let templateIndex =
    storeData?.inspectionData?.data?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
  let prevData = storeData?.inspectionData?.data;
  delete prevData?.report_media_library_files;
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter(
    (item) => item?.merge_id == mergeId
  );
  let apiResponseDataFromStore = JSON?.parse(
    JSON?.stringify(storeData?.inspectionData?.data)
  );
  let PdfTemplateResponseData = apiResponseDataFromStore?.inspectiontemplates[templateIndex];

  const handleClosePdf = () => {
    setReOrderMedia(false);
    setShowPdfEditModal(false);
    // setRefresh(prev=>!prev)
    setImage("");
    setDeleteMedia(false);
  };
  const handleDeleteAction = () => {
    setDeleteMedia(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const deleteClickedMedia = (media) => {
    setDeleteAlert(true);
    setActiveDeleteMedia(media);
  };

  const handleCaption = (image) => {
    setShowDescriptionModal(true);
    setCaption(image.description);
    setCurrentMedia(image);
  };

  const handleCloseDescriptionModal = () => {
    // setRefresh(prev=>!prev);
    setCaptionChanged(false);
    setShowDescriptionModal(false);
  };

  const handleCloseDeleteAlert = () => {
    setDeleteAlert(false);
  };

  const handleCloseDel = () => {
    setReOrderMedia(false);
    setShowPdfEditModal(false);
    // setRefresh(prev=>!prev)
    setImage("");
    setDeleteMedia(false);
  };

  const handleConfirmClickedMediaDelete = async () => {
    setLoader(true);
    let deleteObject = ElementpdfData?.[0]?.value;
    let selectedIndex = deleteObject?.findIndex(
      (media) => media?.id == activeDeleteMedia?.id
    );
    deleteObject[selectedIndex].is_deleted = 1;
    deleteObject[selectedIndex].updated_at = await GetUTCTime();
    let newData = deleteObject.filter(
      (item) => item.id == activeDeleteMedia.id
    );
    const itemIndex = pdfTemplate?.pdf_data?.findIndex(
      (item) => item?.merge_id == mergeId
    );
    if (itemIndex != -1) {
      pdfTemplate.pdf_data[itemIndex].value = newData;
      pdfTemplate.pdf_data[itemIndex].updated_at = await GetUTCTime();
      PdfTemplateResponseData.pdf_data[itemIndex].value = deleteObject;
      PdfTemplateResponseData.pdf_data[itemIndex].updated_at = await GetUTCTime();
    }
    prevData.inspectiontemplates[templateIndex].pdf_categories = [];
    let filteredPdfData = prevData.inspectiontemplates[templateIndex].pdf_data.filter(el=> el.merge_id === mergeId)
    prevData.inspectiontemplates[templateIndex].pdf_data = filteredPdfData;
    let filteredObject = filterObjectsUptoTemplate(prevData, templateIndex);
    setDeleteAlert(false);
    UpdateTemplateMediaDataSpecific(dispatch,apiResponseDataFromStore,filteredObject);
  };

  const handlemediaDesc = async () => {
    let DescObject = ElementpdfData?.[0]?.value;
    let selectedIndex = DescObject?.findIndex(
      (media) => media?.id == currentMedia?.id
    );
    let newData = DescObject.filter((item) => item.id == currentMedia.id);
    DescObject[selectedIndex].updated_at = await GetUTCTime();
    DescObject[selectedIndex].description = caption;
    const itemIndex = pdfTemplate?.pdf_data?.findIndex(
      (item) => item?.merge_id == mergeId
    );
    if (itemIndex != -1) {
      pdfTemplate.pdf_data[itemIndex].value = DescObject;
      pdfTemplate.pdf_data[itemIndex].updated_at = await GetUTCTime();
      PdfTemplateResponseData.pdf_data[itemIndex].value = newData;
      PdfTemplateResponseData.pdf_data[itemIndex].updated_at = await GetUTCTime();
    }
    setShowDescriptionModal(false);
    prevData.inspectiontemplates[templateIndex].pdf_categories = [];
    let filteredPdfData = prevData.inspectiontemplates[templateIndex].pdf_data.filter(el=> el.merge_id === mergeId)
    prevData.inspectiontemplates[templateIndex].pdf_data = filteredPdfData;
    let filteredObject = filterObjectsUptoTemplate(prevData, templateIndex);
    UpdateTemplateDataSpecificWithLoader(dispatch, apiResponseDataFromStore, filteredObject);
  };
  // reorder
  const reorderApicall = async (result) => {
    result?.map(async (item, index) => {
      item.updated_at = await GetUTCTime();
      item.order = index;
    });
    const itemIndex = pdfTemplate?.pdf_data?.findIndex(
      (item) => item?.merge_id == mergeId
    );
    pdfTemplate.pdf_data[itemIndex].value = result;
    pdfTemplate.pdf_data[itemIndex].updated_at = await GetUTCTime();
    PdfTemplateResponseData.pdf_data[itemIndex].value = result;
    PdfTemplateResponseData.pdf_data[itemIndex].updated_at = await GetUTCTime();
    prevData.inspectiontemplates[templateIndex].pdf_categories = [];
    let filteredPdfData = prevData.inspectiontemplates[templateIndex].pdf_data.filter(el=> el.merge_id === mergeId)
    prevData.inspectiontemplates[templateIndex].pdf_data = filteredPdfData;
    let filteredObject = filterObjectsUptoTemplate(prevData, templateIndex);
    UpdateTemplateDataSpecific(dispatch, apiResponseDataFromStore, filteredObject);
  };

  // reorder
  const moveCard = (dragIndex, hoverIndex) => {
    setReorderMediaData((prevCards) => {
      return update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      });
    });
    setAfterReorderMediaData(
      update(mediaData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, mediaData[dragIndex]],
        ],
      })
    );
  };

  const renderCard = (image, index) => {
    return (
      <PdfReorderMedia
        handleCaption={handleCaption}
        reorderApicall={reorderApicall}
        afterReodermediaData={afterReodermediaData}
        inspectionData={inspectionData}
        reOrderMedia={reOrderMedia}
        key={image.id}
        index={index}
        id={image.id}
        item={image}
        moveCard={moveCard}
      />
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleAddMedia(acceptedFiles, "drag");
    },
    [handleAddMedia]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
    }
  }, [storeData.inspectionData]);

  useEffect(() => {
    setInspectionData(storeData.inspectionData?.data);
  }, [storeData.inspectionData]);
  return (
    <>
      {/* caption */}
      <Modal
        open={showDescriptionModal}
        onClose={handleCloseDescriptionModal}
        BackdropProps={{ style: { opacity: "60%" } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mark-as-complete-modal-main"
      >
        <Box
          className="mark-as-complete-modal"
          sx={{ minHeight: "211px !important", top: "40% !important" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon
                sx={{
                  float: "right",
                  cursor: "pointer",
                  color: "#A5A5A5",
                  mt: 1,
                  mr: 1,
                }}
                onClick={handleCloseDescriptionModal}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <p className="modal-heading">Add caption </p>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                textAlign: "center",
                mt: "14px",
                justifyContent: "center",
              }}
            >
              <p className="modal-content">This will appear in the report.</p>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                textAlign: "center",
                mt: "20px",
                justifyContent: "center",
              }}
            >
              <FormControl variant="standard" className="forms-control_caption">
                <label
                  className="input-form-label text-left"
                  htmlFor="statement"
                  id="demo-simple-select-label"
                >
                  Caption
                </label>
                <TextareaAutosize
                  className="textArea"
                  id="statement"
                  aria-label="minimum height"
                  minRows={4}
                  placeholder=""
                  style={{ width: "100%", resize: "none" }}
                  onChange={(e) => {
                    setCaption(e.target.value);
                    setCaptionChanged(true);
                  }}
                  value={caption}
                  InputProps={{
                    inputComponent: TextareaAutosize,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                textAlign: "center",
                mt: "24px",
                justifyContent: "center",
                gap: "20px",
                mb: "10px",
              }}
            >
              <FilledButton
                width="150px"
                text="save"
                disabled={!captionChanged}
                onClick={() => handlemediaDesc()}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* caption end */}

      {/* delete alert dialog */}
      <DeleteConfirmationDialog
        open={deleteAlert}
        handleClose={handleCloseDeleteAlert}
        title={"Delete this media?"}
        contentText={"This action cannot be undone."}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDeleteAlert}
        submitBtnTitle={"Delete"}
        submitBtnAction={handleConfirmClickedMediaDelete}
      />
      {/* delete dialog end */}

           {showPdfEditModal ? (        
        <Modal open><div className="galleryModal" style={{ zIndex: 10 }}>
        <div className="gallery-modal-content">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div className="close-wrapper-category">
                <CloseIcon onClick={handleClosePdf} />
              </div>
              <DialogTitle className="category-dialog-title">{"Media"}</DialogTitle>
              <div {...getRootProps()} onClick={(e) => e?.stopPropagation()}>
              <DialogContent
          className="dialog-content-media"
          sx={{ border: isDragActive && "2px dashed #4282e2" }}
        >
          <Grid container className="media-galley-container">
            {!reOrderMedia && (
              <div className="add-media-content">
                {                    
                  <Typography
                    className="edit__media__add_btn mt-0"
                    onClick={() => editMediaInput.current.click()}
                  >
                    <input
                      ref={editMediaInput}
                      type="file"
                      accept="image/*,video/*"
                      onChange={handleAddMedia}
                      style={{ display: "none" }}
                      multiple
                    />
                    <div>
                      <AddCircleIcon />
                    </div>
                    <div
                      style={{
                        textDecoration: "underline",
                        color: "#4282e2",
                      }}
                    >
                      Click to add media
                    </div>
                    <div>or drag and drop</div>
                  </Typography>
                }
              </div>
            )}
            {!reOrderMedia ? (
              mediaData?.map((image, index) =>
                image?.is_deleted !== 1 ? (
                  image?.online === "online" &&
                  image?.filetype === "image" ? (
                    <div className="galley_image" key={index}>
                      {deleteMedia && (
                        <DeleteIcon
                          className="media_delete_icon"
                          onClick={() => deleteClickedMedia(image)}
                        />
                      )}
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          objectFit: "contain",
                          backgroundColor: "#FAFAFA",
                        }}
                        src={
                          image.filetype === "image"
                            ? image.is_portal == 1
                              ? `${inspectionData?.bucket_url_2}${
                                  image.thumbnail !== null
                                    ? image.thumbnail
                                    : image.onlineUrl
                                }`
                              : `${inspectionData?.bucket_url}${
                                  image.thumbnail !== null
                                    ? image.thumbnail
                                    : image.onlineUrl
                                }`
                            : image.is_portal == 1
                            ? `${inspectionData?.bucket_url_2}${
                                image.thumbnail !== null
                                  ? image.thumbnail
                                  : image.onlineUrl
                              }`
                            : `${inspectionData?.bucket_url}${
                                image.thumbnail !== null
                                  ? image.thumbnail
                                  : image.onlineUrl
                              }`
                        }
                        alt="image"
                      ></img>
                      <p className="image_description_content">
                        {image?.description !== null &&
                        image?.description !== undefined &&
                        image?.description?.length > 45 ? (
                          <span onClick={() => handleCaption(image)}>
                            {" "}
                            {`${image.description?.substring(45, 0)}...`}
                          </span>
                        ) : (
                          <span onClick={() => handleCaption(image)}>
                            {image.description}
                          </span>
                        )}
                      </p>
                      {image?.description === null ||
                      image?.description === undefined ||
                      image?.description === "" ? (
                        <span
                          className="image_description"
                          onClick={() => handleCaption(image)}
                        >
                          <AddCircleIcon />
                          {image?.description !== null &&
                          image?.description !== undefined &&
                          image?.description !== ""
                            ? "Edit Caption"
                            : "Caption"}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : image.online === "offline" ? (
                    ""
                  ) : (
                    <div className="galley_image" key={index}>
                      {deleteMedia && (
                        <DeleteIcon
                          className="media_delete_icon"
                          onClick={() => deleteClickedMedia(image)}
                        />
                      )}
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          objectFit: "contain",
                          backgroundColor: "#FAFAFA",
                        }}
                        src={
                          image.filetype === "image"
                            ? image.is_portal == 1
                              ? `${inspectionData?.bucket_url_2}${
                                  image.thumbnail !== null
                                    ? image.thumbnail
                                    : image.onlineUrl
                                }`
                              : `${inspectionData?.bucket_url}${
                                  image.thumbnail !== null
                                    ? image.thumbnail
                                    : image.onlineUrl
                                }`
                            : image.is_portal == 1
                            ? `${inspectionData?.bucket_url_2}${
                                image.thumbnail !== null
                                  ? image.thumbnail
                                  : image.onlineUrl
                              }`
                            : `${inspectionData?.bucket_url}${
                                image.thumbnail !== null
                                  ? image.thumbnail
                                  : image.onlineUrl
                              }`
                        }
                        alt="image"
                      ></img>
                      <PlayCircleIcon className="play_icon" />
                      <p className="image_description_content">
                        {image?.description !== null &&
                        image?.description !== undefined &&
                        image?.description?.length > 45 ? (
                          <span
                            onClick={() => handleCaption(image)}
                          >{`${image.description?.substring(
                            45,
                            0
                          )}...`}</span>
                        ) : (
                          <span onClick={() => handleCaption(image)}>
                            {image.description}
                          </span>
                        )}
                      </p>
                      {image?.description === null ||
                      image?.description === undefined ||
                      image?.description === "" ? (
                        <span
                          className="image_description"
                          onClick={() => handleCaption(image)}
                        >
                          <AddCircleIcon />
                          {image?.description !== null &&
                          image?.description !== undefined &&
                          image?.description !== ""
                            ? "Edit Caption"
                            : "Caption"}{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                ) : (
                  ""
                )
              )
            ) : (
              <>
                {
                  <div className="add-media-content">
                    {
                      <Typography
                        className="edit__media__add_btn mt-0"
                        onClick={() => editMediaInput.current.click()}
                      >
                        <input
                          ref={editMediaInput}
                          type="file"
                          accept="image/*,video/*"
                          onChange={handleAddMedia}
                          style={{ display: "none" }}
                          multiple
                        />
                        <div>
                          <AddCircleIcon />
                        </div>
                        <div
                          style={{
                            textDecoration: "underline",
                            color: "#4282e2",
                          }}
                        >
                          Click to add media
                        </div>
                        <div>or drag and drop</div>
                      </Typography>
                    }
                  </div>
                }
                {reorderMediaData?.map((image, index) =>
                  renderCard(image, index)
                )}
              </>
            )}
          </Grid>
        </DialogContent>
              </div>
            </div>
            <div>
              <Divider style={{ width: "100%" }} />
              <DialogActions className="media-modal-action">
        {!reOrderMedia && (
          <Grid className="media-edit-action">
            {mediaData == undefined || mediaData?.length == 0 ? (
              ""
            ) : (
              <span onClick={() => setReOrderMedia(true)}>
                <ReorderIcon />
                Reorder media
              </span>
            )}
            {mediaData == undefined || mediaData?.length == 0 ? (
              ""
            ) : (
              <span onClick={() => setDeleteMedia(true)}>
                <DeleteOutlineIcon />
                Delete media
              </span>
            )}
          </Grid>
        )}

        {reOrderMedia ? (
          <Button
            className="add-category-btn w-100"
            onClick={() => {
              setReOrderMedia(false);
            }}
          >
            Done
          </Button>
        ) : deleteMedia ? (
          <Button
            className="add-category-btn w-100"
            onClick={handleDeleteAction}
          >
            Done
          </Button>
        ) : (
          <Button
            className="add-category-btn w-100"
            onClick={() => handleCloseDel()}
          >
            Save
          </Button>
        )}
      </DialogActions>
            </div>
          </div>
        </div>  
      </div>
      </Modal>
      ) : (
        ""
      )}
    </>
  );
}

export default EditPdfMedia;
