import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReorderIcon from "@mui/icons-material/Reorder";
import { v4 as uuidv4 } from "uuid";

import React, { useState } from "react";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecificWithLoader } from "../../Helpers/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import toast from "react-hot-toast";
import { useEffect } from "react";
import {  filterObjectsUptoTemplate } from "../../Helpers/HelperFunctions";

function EditLocationModal({
  editLocationModal,
  setEditLocationModal,
  colOneLocationData,
  colTwoLocationData,
  colThreeLocationData,
  currentLocation,
  setShowLocationModal,
  locationData,
  setColOneLocationData,
  setColTwoLocationData,
  setColThreeLocationData,
  type,
  inspectionResponse,
  templateId,
  categoryId,
  lineUuid,
  commentUuid,
  addNewLocationModal,
  setAddNewLocationModal,
}) {
  const [addNewLocationTag, setAddNewLocationTag] = useState("");
  const [addNewLocationPopupType, setaddNewLocationPopupType] = useState("");
  const [addNewLocationTagError, setAddNewLocationTagError] = useState(false);
  const [editLocationToast, setEditLocationToast] = useState(false);
  const [addNewLocationTagErrorMessage, setAddNewLocationTagErrorMessage] =
    useState("");
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);

  const handleAddNewClose = () => {
    setAddNewLocationModal(false);
    setEditLocationModal(true);
    setAddNewLocationTag("");
    setAddNewLocationTagError(false);
    setAddNewLocationTagErrorMessage("");
  };

  const handleEditClose = () => {
    setEditLocationModal(false);
    setShowLocationModal(true);
  };

  const addNewLocationHandle = (popup) => {
    setAddNewLocationModal(true);
    setEditLocationModal(false);
    setaddNewLocationPopupType(popup);
  };

  const validationAddNewLocation = () => {
    if (addNewLocationTag?.trim() === "") {
      setAddNewLocationTagError(true);
      setAddNewLocationTagErrorMessage("Please enter a location");
    } else {
      setAddNewLocationTagError(false);
      setAddNewLocationTagErrorMessage("");
      if (addNewLocationPopupType == 1) {
        const addedArray = {
          col: "1",
          order: String(colOneLocationData?.length + 1),
          uuid: uuidv4(),
          value: addNewLocationTag,
        };
        setColOneLocationData([...colOneLocationData, addedArray]);
      }
      if (addNewLocationPopupType == 2) {
        const addedArray = {
          col: "2",
          order: String(colTwoLocationData?.length + 1),
          uuid: uuidv4(),
          value: addNewLocationTag,
        };
        setColTwoLocationData([...colTwoLocationData, addedArray]);
      }
      if (addNewLocationPopupType == 3) {
        const addedArray = {
          col: "3",
          order: String(colThreeLocationData?.length + 1),
          uuid: uuidv4(),
          value: addNewLocationTag,
        };
        setColThreeLocationData([...colThreeLocationData, addedArray]);
      }
      setAddNewLocationTag("");
      setEditLocationModal(true);
      setAddNewLocationModal(false);
    }
  };

  const handleDelete = (uuid, colNum) => {
    if (colNum == "1")
      setColOneLocationData(
        colOneLocationData.filter((val) => (val.uuid != uuid ? true : false))
      );
    if (colNum == "2")
      setColTwoLocationData(
        colTwoLocationData.filter((val) => (val.uuid != uuid ? true : false))
      );
    if (colNum == "3")
      setColThreeLocationData(
        colThreeLocationData.filter((val) => (val.uuid != uuid ? true : false))
      );
  };

  const handleEditApiCall = async () => {
    let finalLocationArray = [
      ...colOneLocationData,
      ...colTwoLocationData,
      ...colThreeLocationData,
    ];
    let prevData = inspectionResponse;
    delete prevData?.report_media_library_files;
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
    prevData.inspectiontemplates[templateIndex].location_data =
      finalLocationArray;
    setEditLocationToast(true);
    let filteredObject = filterObjectsUptoTemplate(prevData, templateIndex);
    UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
    setEditLocationModal(false);
    setShowLocationModal(true);
  };

  // reorder colone
  const reordercolOne = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndcolOne = async (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItemscolOne = reordercolOne(
      colOneLocationData,
      result.source.index,
      result.destination.index
    );
    reorderedItemscolOne.forEach((x, i) => {
      x.order = i + 1;
      return x;
    });
    setColOneLocationData(reorderedItemscolOne);
  };

  // reorder for coltwo
  const reordercolTwo = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndcolTwo = async (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItemscolTwo = reordercolTwo(
      colTwoLocationData,
      result.source.index,
      result.destination.index
    );
    reorderedItemscolTwo.forEach((x, i) => {
      x.order = i + 1;
      return x;
    });
    setColTwoLocationData(reorderedItemscolTwo);
  };

  // reorder for colthree
  const reordercolThree = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndcolThree = async (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItemscolThree = reordercolThree(
      colThreeLocationData,
      result.source.index,
      result.destination.index
    );
    reorderedItemscolThree.forEach((x, i) => {
      x.order = i + 1;
      return x;
    });
    setColThreeLocationData(reorderedItemscolThree);
  };

  useEffect(() => {
    if (
      storeData?.updatedInspectionData?.success === true &&
      editLocationToast
    ) {
      toast.success("The location list for your inspection has been updated.");
      setEditLocationToast(false);
    }
  }, [handleEditApiCall]);

  return (
    <>
      <Dialog
        className="dialog-container-location2"
        open={editLocationModal}
        onClose={handleEditClose}
        aria-labelledby="category-modal"
        aria-describedby="category-modal-description"
      >
        <div className="close-wrapper-new-category">
          <ArrowBackIcon onClick={handleEditClose} />
        </div>
        <DialogTitle
          className="category-dialog-title"
          sx={{ marginBottom: "20px" }}
        >
          {"Edit location list"}
        </DialogTitle>
        <DialogContent className="dialog-content-category overflow-x-hide">
          <Grid container className="input-controll">
            <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper">
              <DragDropContext onDragEnd={onDragEndcolOne}>
                <Droppable
                  droppableId={String(Math.floor(Math.random() * 100))}
                >
                  {(provided) => (
                    <div ref={provided.innerRef}>
                      {colOneLocationData?.map((item, index) => {
                        return (
                          <>
                            <Draggable
                              draggableId={String(item.uuid)}
                              // isDragDisabled ={actives && items.line_id === ids}
                              key={item.uuid}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <div className="edit-loc-item">
                                    <ReorderIcon sx={{ color: "black" }} />{" "}
                                    <span
                                      style={{
                                        marginLeft: "4px",
                                        marginRight: "4px",
                                      }}
                                    >
                                      {item.value}
                                    </span>{" "}
                                    <RemoveCircleOutlineIcon
                                      sx={{ color: "red" }}
                                      onClick={() =>
                                        handleDelete(item.uuid, "1")
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </>
                        );
                      })}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <Grid
                className="location-edit-action"
                onClick={() => {
                  addNewLocationHandle("1");
                }}
              >
                <p className="location-edit">
                  <AddCircleIcon /> Add new location
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper">
              <DragDropContext onDragEnd={onDragEndcolTwo}>
                <Droppable
                  droppableId={String(Math.floor(Math.random() * 100))}
                >
                  {(provided) => (
                    <div ref={provided.innerRef}>
                      {colTwoLocationData?.map((item, index) => {
                        return (
                          <>
                            <Draggable
                              draggableId={String(item.uuid)}
                              // isDragDisabled ={actives && items.line_id === ids}
                              key={item.uuid}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <div className="edit-loc-item">
                                    <ReorderIcon sx={{ color: "black" }} />{" "}
                                    <span
                                      style={{
                                        marginLeft: "4px",
                                        marginRight: "4px",
                                      }}
                                    >
                                      {item.value}
                                    </span>{" "}
                                    <RemoveCircleOutlineIcon
                                      sx={{ color: "red" }}
                                      onClick={() =>
                                        handleDelete(item.uuid, "2")
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </>
                        );
                      })}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Grid
                className="location-edit-action"
                onClick={() => {
                  addNewLocationHandle("2");
                }}
              >
                <p className="location-edit">
                  <AddCircleIcon /> Add new location
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper">
              <DragDropContext onDragEnd={onDragEndcolThree}>
                <Droppable
                  droppableId={String(Math.floor(Math.random() * 100))}
                >
                  {(provided) => (
                    <div ref={provided.innerRef}>
                      {colThreeLocationData?.map((item, index) => {
                        return (
                          <>
                            <Draggable
                              draggableId={String(item.uuid)}
                              // isDragDisabled ={actives && items.line_id === ids}
                              key={item.uuid}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <div className="edit-loc-item">
                                    <ReorderIcon sx={{ color: "black" }} />{" "}
                                    <span
                                      style={{
                                        marginLeft: "4px",
                                        marginRight: "4px",
                                      }}
                                    >
                                      {item.value}
                                    </span>{" "}
                                    <RemoveCircleOutlineIcon
                                      sx={{ color: "red" }}
                                      onClick={() =>
                                        handleDelete(item.uuid, "3")
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </>
                        );
                      })}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Grid
                className="location-edit-action"
                onClick={() => {
                  addNewLocationHandle("3");
                }}
              >
                <p className="location-edit">
                  <AddCircleIcon /> Add new location
                </p>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider style={{ width: "100%" }} />
        <DialogActions className="category-modal-action">
          <Button
            className="add-category-btn w100 loc-btn"
            onClick={() => handleEditApiCall()}
          >
            save changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* add new location */}
      <Dialog
        className="dialog-container-new-option"
        open={addNewLocationModal}
        onClose={handleAddNewClose}
        aria-labelledby="category-modal"
        aria-describedby="category-modal-description"
      >
        <div className="close-wrapper-new-category">
          <ArrowBackIcon onClick={handleAddNewClose} />
        </div>
        <DialogTitle className="new-category-dialog-title">
          {"Add new location"}
        </DialogTitle>

        <DialogContent className="dialog-content-new-option">
          <Grid container className="input-controll">
            <Grid item xs={12}>
              <FormControl variant="standard" className="forms-control w-100">
                <label className="input-form-label" id="categoryName">
                  Location
                </label>
                <TextField
                  id="legacy_user"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  onChange={(e) => {
                    setAddNewLocationTag(e.target.value);
                  }}
                  value={addNewLocationTag}
                  name="categoryName"
                  error={addNewLocationTagError}
                  helperText={addNewLocationTagErrorMessage}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="new-category-modal-action">
          <Button
            className="add-category-btn"
            onClick={() => validationAddNewLocation()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditLocationModal;
