import { Button, ButtonGroup } from "@mui/material";
import "../../../Assets/Styles/Containers/_RLButtonGroup.scss";
const RLButtonGroup = ({ selectedId, handleFilterButtonChange, sortType, selectedType, RLInitialData }) => {
    /**
     * Handles the filter button change event.
     *
     * @param {string} id - The id of the filter button clicked
     */
    return <ButtonGroup variant='none' className='filter-button-group-container'>
        <Button
            variant='none'
            className={selectedId === 'All' ? 'filter-button-tab-selected' : 'filter-button-tab'}
            id='All'
            onClick={() => handleFilterButtonChange('All', selectedType, sortType, RLInitialData)}
        >
            <span className={selectedId === 'All' ? 'filter-buttongrp-selected-textfield' : 'filter-buttongrp-textfield'}>
                All
            </span>
        </Button>

        <Button
            variant='none'
            className={selectedId === 'Images' ? 'filter-button-tab-selected' : 'filter-button-tab'}
            id='Images'
            onClick={() => handleFilterButtonChange('Images', selectedType, sortType, RLInitialData)}
        >
            <span className={selectedId === 'Images' ? 'filter-buttongrp-selected-textfield' : 'filter-buttongrp-textfield'}>
                Images
            </span>
        </Button>

        <Button
            variant='none'
            className={selectedId === 'Videos' ? 'filter-button-tab-selected' : 'filter-button-tab-last-child '}
            id='Videos'
            onClick={() => handleFilterButtonChange('Videos', selectedType, sortType, RLInitialData)}
        >
            <span className={selectedId === 'Videos' ? 'filter-buttongrp-selected-textfield' : 'filter-buttongrp-textfield'}>
                Videos
            </span>
        </Button>
    </ButtonGroup>
}

export default RLButtonGroup;