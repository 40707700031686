import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {  Grid } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import '../../../Assets/Styles/Containers/_button.scss';
import '../../../Assets/Styles/Containers/_inspection.scss';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import { v4 as uuidv4 } from 'uuid';
import {  UpdateTemplateDataSpecificWithLoader } from '../../Helpers/ApiCall';
import { filterObjectsUptoLine } from '../../Helpers/HelperFunctions';

const CreateNewDescriptionModal = (props) => {
  const {showCreateDescriptionModal,setShowCreateDescriptionModal,setShowAddDescriptionModal,descriptionArray,descriptionData,setDescriptionArray,descriptionArrayCopy,setNewEmptyDescription,handleDescriptionSelection,selectedDescription,templateId,categoryId,lineUuid,editAadditionalInp,isInspDetails,currentAdditionalInputID} = props;
  const [descriptionName,setDescriptionName] = useState(''); 
  const [descriptionError,setDescriptionError] = useState(false);
  const [descriptionErrorText,setDescriptionErrorText] = useState('');
  const [inspectionResponse,setInspectionResponse] = useState()
  const [defalutDescriptionArray,setDefaultDescriptionArray] = useState([]);
   // redux
   const dispatch = useDispatch();
   const storeData = useSelector((state) => state.CommonReducer);
  const handleClose=()=>{
    setShowCreateDescriptionModal(false);
    setShowAddDescriptionModal(true);
    setDescriptionError(false)
    setDescriptionErrorText("")
  }

  const checkSelection=()=>{
   let selectionStatus = defalutDescriptionArray?.map(item=>{
      if(item.is_selected === true) return "true"
      else return "false"
    })
    if (selectionStatus?.some((el) => el === "true")) return true;
    else return false;
  }

  const updatePopupView=()=>{
    let selStatus = checkSelection();
    if(selStatus)
    {
      setShowCreateDescriptionModal(false);

      setShowAddDescriptionModal(true)
    }else
    {
      setShowCreateDescriptionModal(false);
      setShowAddDescriptionModal(false)
    }
  }

  const createNewDescription=async(name)=>{
    let temDesArrayforAPIPayload = [...descriptionArray]
    let selStatus = checkSelection();
    if(name.trim() !== "") {
      setDescriptionError(false);
      setDescriptionErrorText("");
      const utc_updated_time = await GetUTCTime();
    let availiableDes = descriptionArray?.map(itm=>itm.value)
    let isPresent = availiableDes?.some(item => item.toLowerCase()?.trim() === name?.toLowerCase()?.trim())
    if(isPresent){
      setDescriptionError(true);
      setDescriptionErrorText("This description is already on the list.")
    }
    else{     
      setDescriptionError(false);
      setDescriptionErrorText("")
      
      if(availiableDes.length === 0){
        temDesArrayforAPIPayload =[{
          "uuid": "",
          "value": name,
          "is_deleted": 0,
          "updated_at": "",
          "is_selected": true
      }]
      }
      else temDesArrayforAPIPayload = [...temDesArrayforAPIPayload,{
        "uuid": uuidv4(),
        "value": name,
        "is_deleted": 0,
        "updated_at": utc_updated_time,
        "is_selected": true
    }]
    setDescriptionArray(temDesArrayforAPIPayload)
      if(!isInspDetails){
        if(editAadditionalInp){
          if(selStatus)
          {  
            updatePopupView()
            var Templates = storeData?.inspectionData?.data?.inspectiontemplates;
            var indexValue = Templates?.findIndex(itm => itm.template_id === templateId);
            var categoryIndex = Templates[indexValue]?.categories?.findIndex(itm => itm.id === categoryId);
            var lineIndex = Templates[indexValue]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid);
            let prevData = storeData?.inspectionData?.data;
            delete prevData?.report_media_library_files;
              let AdditionalInputControlIndex = Templates[indexValue]?.categories[
                  categoryIndex
              ]?.lines[lineIndex]?.line_input_controls?.findIndex((itm) => {
                  return (
                      itm.type === "Additional Input" &&
                      itm.line_input_control_id == currentAdditionalInputID
                  );
              });  
              prevData.inspectiontemplates[indexValue].categories[
                categoryIndex
              ].lines[lineIndex].line_input_controls[
                AdditionalInputControlIndex
              ].values = temDesArrayforAPIPayload;
              prevData.inspectiontemplates[indexValue].categories[
                categoryIndex
              ].lines[lineIndex].line_input_controls[
                AdditionalInputControlIndex
              ].data.push(name);
              prevData.inspectiontemplates[indexValue].categories[
                categoryIndex
              ].lines[lineIndex].line_input_controls[
                AdditionalInputControlIndex
              ].updated_at = await GetUTCTime();
              let filteredObject = filterObjectsUptoLine(prevData, indexValue, categoryIndex, lineIndex);
              UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
          }else
          {     
            updatePopupView()
            var Templates = storeData?.inspectionData?.data?.inspectiontemplates;
          var indexValue = Templates?.findIndex(itm => itm.template_id === templateId);
          var categoryIndex = Templates[indexValue]?.categories?.findIndex(itm => itm.id === categoryId);
          var lineIndex = Templates[indexValue]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid);
          let prevData = storeData?.inspectionData?.data;
          delete prevData?.report_media_library_files;
            let AdditionalInputControlIndex = Templates[indexValue]?.categories[
                categoryIndex
            ]?.lines[lineIndex]?.line_input_controls?.findIndex((itm) => {
                return (
                    itm.type === "Additional Input" &&
                    itm.line_input_control_id == currentAdditionalInputID
                );
            });  
            prevData.inspectiontemplates[indexValue].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              AdditionalInputControlIndex
            ].values = temDesArrayforAPIPayload;
            prevData.inspectiontemplates[indexValue].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              AdditionalInputControlIndex
            ].data.push(name);
            prevData.inspectiontemplates[indexValue].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              AdditionalInputControlIndex
            ].updated_at = await GetUTCTime();
            let filteredObject = filterObjectsUptoLine(prevData, indexValue, categoryIndex, lineIndex);
            UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
          }
          
          }
        else{      
          let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
          let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id === categoryId);
          let lineIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid)
          let linIpIdx = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(lineInpt=>lineInpt.type === "Description")
          let prevData = inspectionResponse?.inspectionData?.data;
          delete prevData?.report_media_library_files;
          let uuidValue = uuidv4();
          
          if(linIpIdx !== -1){   
            if(selStatus)
          {
            updatePopupView()
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].values = temDesArrayforAPIPayload;   
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].data.push(name);
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].updated_at = await GetUTCTime();
            let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
            UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
          }else
          { 
            updatePopupView()
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].values = temDesArrayforAPIPayload;
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].data.push(name);
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].updated_at = await GetUTCTime();
            let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
            UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
            }
          }
        else{
          if(selStatus)
          {
            updatePopupView()
            let discriptionObject = {
              line_input_control_id: null,
              uuid: uuidv4(),
              prompt: "Description",
              values: [
                {
                  value: name,
                  is_selected: true,
                  updated_at: await GetUTCTime(),
                  is_deleted: 0,
                  uuid: uuidValue,
                },
              ],
              type: "Description",
              data: [name],
              updated_at: await GetUTCTime(),
              created_at: await GetUTCTime(),
            };
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls.push(discriptionObject);
            let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
            UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
          }else
          { 
            updatePopupView()
              let discriptionObject = {
                line_input_control_id: null,
                uuid: uuidv4(),
                prompt: "Description",
                values: [
                  {
                    value: name,
                    is_selected: true,
                    updated_at: await GetUTCTime(),
                    is_deleted: 0,
                    uuid: uuidValue,
                  },
                ],
                type: "Description",
                data: [name],
                updated_at: await GetUTCTime(),
                created_at: await GetUTCTime(),
              };
              prevData.inspectiontemplates[templateIndex].categories[
                categoryIndex
              ].lines[lineIndex].line_input_controls.push(discriptionObject);
              let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
              UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
        }
      }
        }   
      }
      else{
          // handleDescriptionSelection(name)
          let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
          let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id === categoryId);
          let lineIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid)
          let linIpIdx = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(lineInpt=>lineInpt.type === "CheckBox")
          let prevData = inspectionResponse?.inspectionData?.data;
          delete prevData?.report_media_library_files;
          let uuidValue = uuidv4();
          if(linIpIdx !== -1){ 
            if(selStatus)
          {
            updatePopupView()
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].values = temDesArrayforAPIPayload;
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].data.push(name);
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].updated_at = await GetUTCTime();
            let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
            UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
          }else
          {
            updatePopupView()
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].values = temDesArrayforAPIPayload;
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].data.push(name);
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].updated_at = await GetUTCTime();
            let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
            UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
            }
          }
        else{
          if(selStatus)
          {
            updatePopupView()
            let discriptionObject = {
              line_input_control_id: null,
              uuid: uuidv4(),
              prompt: "CheckBox",
              values: [
                {
                  value: name,
                  is_selected: true,
                  updated_at: await GetUTCTime(),
                  is_deleted: 0,
                  uuid: uuidValue,
                },
              ],
              type: "CheckBox",
              data: [name],
              updated_at: await GetUTCTime(),
              created_at: await GetUTCTime(),
            };
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls.push(discriptionObject);
            let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
            UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);          }else
          { 
            updatePopupView()
              let discriptionObject = {
                line_input_control_id: null,
                uuid: uuidv4(),
                prompt: "CheckBox",
                values: [
                  {
                    value: name,
                    is_selected: true,
                    updated_at: await GetUTCTime(),
                    is_deleted: 0,
                    uuid: uuidValue,
                  },
                ],
                type: "CheckBox",
                data: [name],
                updated_at: await GetUTCTime(),
                created_at: await GetUTCTime(),
              };
              prevData.inspectiontemplates[templateIndex].categories[
                categoryIndex
              ].lines[lineIndex].line_input_controls.push(discriptionObject);
              let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
              UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
        }
      }
           
      }     
      // handleDescriptionSelection(name) 
    setDescriptionName('');
  }
    } else {
      setDescriptionError(true);
      setDescriptionErrorText("Please add a description");
    }
}

const handleDescriptionChange = (e) => {
  const { value } = e.target;
  setDescriptionName(value);
  setDescriptionError(false);
  setDescriptionErrorText("");
}

  useEffect(() => {
    if(storeData.inspectionData) setInspectionResponse(storeData)
  }, [storeData.inspectionData])

  useEffect(() => {
    let addedLine = JSON.parse(JSON.stringify(descriptionArrayCopy!==undefined?descriptionArrayCopy:[]));
    setDefaultDescriptionArray(addedLine)   
    checkSelection()
  }, [showCreateDescriptionModal])
  
  return (
    <Dialog className='dialog-container-new-category'
    open={showCreateDescriptionModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
   <div className="close-wrapper-new-category">
      <ArrowBackIcon onClick={handleClose} id='close_new_des_modal'/>
    </div>
    <DialogTitle className='new-category-dialog-title'>{"Add new option"}</DialogTitle>
   
    <DialogContent className="dialog-content-new-category">
      <Grid container className="input-controll">
        <Grid item xs={12} >
        <FormControl variant="standard" className="forms-control w-100">
              <label
                className="input-form-label"
                id="categoryName"
              >
               {descriptionData === undefined ? "Description" : (descriptionData?.prompt === null ? "" : descriptionData?.prompt)}
              </label>
              <TextField id="new_des_input" className="input-textfield" size="small" variant="outlined"
                type="text"
                onChange={handleDescriptionChange}
                value={descriptionName}
                name="categoryName"
                error={descriptionError}
                helperText={descriptionErrorText}
              />
            </FormControl>

            {/* <FormControlLabel className='add_new_category_checkbox' control={<Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}/>} label="Save to template" /> */}
        </Grid>

      </Grid>
    </DialogContent>
    <DialogActions className='new-category-modal-action'>          
      <Button className="add-category-btn" 
      onClick={()=>createNewDescription(descriptionName)}
      id="save_new_description_button"
       >
        Save
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default CreateNewDescriptionModal