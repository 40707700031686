import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "../../../../Assets/Styles/Containers/_button.scss";
import "../../../../Assets/Styles/Containers/_inspection.scss";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";

const AddCommentModal = ({
  showCommentModal,
  setShowCommentModal,
  editComment,
  setEditComment,
  templateId,
  categoryId,
  lineUuid,
  prompt,
  recommendation,
  commentUuid,
  is_mannual_prompt,
  disableCommentBtn,
  setDisableCommentBtn,
}) => {
  const [editorContent, setEditorContent] = useState("");
  const [commentTitle, setCommentTitle] = useState("");
  const [inspectionData, setInspectionData] = useState("");

  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const handleClose = () => {
    setShowCommentModal(false);
    setEditComment(false);
    setEditorContent("");
    setCommentTitle("");
  };
  const handleChange = (content) => {
    function decodeEntities(content) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = content;
      return textarea.value;
    }
    setEditorContent(decodeEntities(content));
  };

  const createNewComment = async (inspectionData, newCommentAdded) => {
    setDisableCommentBtn(true);
    let templateIndex = inspectionData?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let prevData = inspectionData;
    delete prevData?.report_media_library_files;
    let commentIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex((itm) => itm.uuid == commentUuid);
    if (!editComment) {
      let newComment = {
        comment_id: null,
        uuid: uuidv4(),
        // prompt: (commentTitle?.trim() === "" || commentTitle === null)? "Untitled" : commentTitle,
        prompt: commentTitle,
        recommendation: newCommentAdded === undefined ? "" : newCommentAdded,
        defaultRating_id: 1,
        default_location: "",
        is_deleted: 0,
        is_selected: 1,
        comment_input_controls: [
          {
            data: [],
            type: "Location",
            uuid: uuidv4(),
            prompt: "Location",
            values: [],
            created_at: await GetUTCTime(),
            updated_at: await GetUTCTime(),
            comment_input_control_id: null,
          },
          {
            data: [],
            type: "Rating",
            uuid: uuidv4(),
            prompt: "Rating",
            values: [],
            created_at: await GetUTCTime(),
            updated_at: await GetUTCTime(),
            comment_input_control_id: null,
          },
          {
            data: [],
            type: "Media",
            uuid: uuidv4(),
            prompt: "Media",
            values: [],
            created_at: await GetUTCTime(),
            updated_at: await GetUTCTime(),
            comment_input_control_id: null,
          },
        ],
        updated_at: await GetUTCTime(),
        created_at: await GetUTCTime(),
      };
      prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments.push(newComment);
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
      setShowCommentModal(false);
    } else {
      // prevData.inspectiontemplates[templateIndex].categories[
      //   categoryIndex
      // ].lines[lineIndex].comments[commentIndex].prompt = (commentTitle?.trim() === "" || commentTitle === null)? "Untitled" : commentTitle;
      if (prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].is_mannual_prompt !== undefined) {
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].prompt = commentTitle;

        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].recommendation = newCommentAdded;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].is_mannual_prompt = 1;

        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].updated_at = await GetUTCTime();
      } else {
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].prompt = commentTitle;

        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].recommendation = newCommentAdded;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].updated_at = await GetUTCTime();
      }
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
      setShowCommentModal(false);
    }
  };
  useEffect(() => {
    if (editComment) {
      if (is_mannual_prompt !== undefined) {
        if (is_mannual_prompt === 0) setCommentTitle("");
        else setCommentTitle(prompt);
      } else setCommentTitle(prompt);

      setEditorContent(recommendation);
    } else {
      setCommentTitle("");
      setEditorContent("");
    }
  }, [editComment, storeData.inspectionData]);
  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionData(storeData.inspectionData?.data);
      setEditorContent(recommendation);
    }
  }, [storeData.inspectionData, editComment, commentUuid]);

  return (
    <Dialog className="dialog-container-new-option" open={showCommentModal} onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
      <div className="close-wrapper-new-category-right">
        <CloseIcon onClick={handleClose} />
      </div>
      <DialogTitle className="editor-dialog-title">{editComment ? "Comment" : "Add Comment"}</DialogTitle>
      <DialogContent className="dialog-content-new-option">
        <Grid item xs={12} style={{ marginBottom: "19px" }}>
          <FormControl variant="standard" className="forms-control">
            <label className="comment-input-form-label" htmlFor="title">
              Title
            </label>
            <TextField
              id="modelnumber"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Add comment title"
              value={commentTitle}
              onChange={(e) => setCommentTitle(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid container className="input-controll">
          <Grid item xs={12} sm={12}>
            <FormControl variant="standard" className="forms-control">
              <label className="comment-input-form-label" htmlFor="Recommendation">
                Recommendation
              </label>

              <SunEditor
                className="editor-class"
                setDefaultStyle="font-size: 14px;"
                height="100%"
                setOptions={{
                  // buttonList:[['bold','italic', 'underline', 'list','fontColor','fontSize']]
                  buttonList: [
                    // default
                    ["bold", "italic", "underline"],
                    ["list"],
                    ["fontColor"],
                    ["fontSize"],
                    // (min-width: 992)
                    ["%992", [["bold", "italic", "underline"], ["list"], ["fontColor"], ["fontSize"]]],
                    // (min-width: 767)
                    ["%767", [["bold", "italic", "underline"], ["list"], ["fontColor"], ["fontSize"]]],
                    // (min-width: 480)
                    ["%480", [["bold", "italic", "underline"], ["list"], ["fontColor"], ["fontSize"]]],
                  ],
                }}
                onChange={handleChange}
                // defaultValue={editorContent}
                setContents={editorContent}
                // getSunEditorInstance={getSunEditorInstance}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="new-category-modal-action">
        <Button className="add-category-btn" disabled={disableCommentBtn} onClick={() => createNewComment(inspectionData, editorContent)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCommentModal;
