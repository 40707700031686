//is_deleted_from_rml values
// 0 - not deleted
// 1 - original
// 2 - edited deleted
// 3 - both deleted

import { GetUTCTime } from "../../../Helpers/GetUTCTime";

const DeleteRMLMedia = (mainData, mediaItem) => {
    return new Promise(async (resolve, reject) => {
        try {
            const utcTime = await GetUTCTime();
            var DataMain = JSON.parse(JSON.stringify(mainData?.inspectionDataForBulkMedia?.data));
            mediaItem.updated_at = utcTime;
            let RMLib = mainData?.inspectionDataForBulkMedia?.data?.report_media_library_files || []
            const rmlMediaIndex = RMLib.findIndex((i) => {
                return i.uuid == mediaItem.uuid;
            });
            if (rmlMediaIndex !== -1) {
                RMLib[rmlMediaIndex]["is_deleted_from_rml"] =
                    mediaItem?.is_deleted_from_rml == 1 || mediaItem?.is_deleted_from_rml == 2 ? 3 : mediaItem?.edited ? 2 : 1;
                RMLib[rmlMediaIndex].updated_at = utcTime;
            }
            DataMain.report_media_library_files = RMLib;
            let order_templates = DataMain?.inspectiontemplates || [];
            order_templates = order_templates.filter((itm) => {
                return itm?.is_deleted !== 1;
            });
            for (let templateIndex in order_templates) {
                let template = order_templates[templateIndex];
                if (template?.is_pdf_template === 1) {
                    //pdf template
                    let pdfData = template?.pdf_data || [];
                    const updatedPDFData = await updatePDFData(pdfData, mediaItem);
                    order_templates[templateIndex].pdf_data = updatedPDFData;
                } else if (template?.is_narrative_template === 1) {
                    //narrative template
                    const updatedNarrativeData = await updateTemplate(template, mediaItem, 1);
                    order_templates[templateIndex] = updatedNarrativeData;
                } else {
                    //normal template
                    const updatedTemplate = await updateTemplate(template, mediaItem, 0);
                    order_templates[templateIndex] = updatedTemplate;
                }
            }
            DataMain.inspectiontemplates = order_templates;
            resolve(DataMain);
        } catch (err) {
            reject(err);
        }
    });
};
const updatePDFData = async (pdfData, mediaItem) => {
    return new Promise(async (resolve, reject) => {
        try {
            const utcTime = await GetUTCTime();
            pdfData.forEach((element, index) => {
                let pdfElement = element;
                //check for media in pdf_data
                if (element?.type == "MediaUploader") {
                    for (let mediaIndex in element?.value || []) {
                        if (element?.value[mediaIndex].uuid == mediaItem.uuid) {
                            pdfElement.value[mediaIndex]["is_deleted_from_rml"] =
                                mediaItem?.is_deleted_from_rml == 1 || mediaItem?.is_deleted_from_rml == 2
                                    ? 3
                                    : mediaItem?.edited
                                        ? 2
                                        : 1;
                            pdfElement.value[mediaIndex].updated_at = utcTime;
                            if (element?.value[mediaIndex]?.edited == mediaItem?.edited) {
                                pdfElement.value[mediaIndex].is_deleted = 1;
                            }
                            pdfElement["updated_at"] = utcTime;
                        }
                    }
                }
                pdfData[index] = pdfElement;
            });
            resolve(pdfData);
        } catch (err) {
            reject(false);
        }
    });
};
const updateTemplate = async (orderTemplate, mediaItem, is_narrative) => {
    return new Promise(async (resolve, reject) => {
        try {
            let template = orderTemplate;
            const utcTime = await GetUTCTime();
            template["updated_at"] = utcTime;
            (template?.categories || []).forEach((category, category_index) => {
                (category?.lines || []).forEach((line, line_index) => {
                    let comments = line?.comments || [];
                    let line_input_controls = line?.line_input_controls || [];
                    if (is_narrative == 0) {
                        line_input_controls.forEach((LInput, LIindex) => {
                            if (LInput?.type == "Media") {
                                let lineMedia = LInput?.data || [];
                                for (let mediaIndex in lineMedia) {
                                    if (lineMedia[mediaIndex].uuid == mediaItem.uuid) {
                                        template.categories[category_index].lines[line_index].line_input_controls[LIindex].data[mediaIndex][
                                            "is_deleted_from_rml"
                                        ] =
                                            mediaItem?.is_deleted_from_rml == 1 || mediaItem?.is_deleted_from_rml == 2
                                                ? 3
                                                : mediaItem?.edited
                                                    ? 2
                                                    : 1;
                                        template.categories[category_index].lines[line_index].line_input_controls[LIindex].data[
                                            mediaIndex
                                        ].updated_at = utcTime;
                                        if (lineMedia[mediaIndex].edited == mediaItem.edited) {
                                            template.categories[category_index].lines[line_index].line_input_controls[LIindex].data[
                                                mediaIndex
                                            ].is_deleted = 1;
                                        }
                                        template.categories[category_index]["updated_at"] = utcTime;
                                        template.categories[category_index].lines[line_index].line_input_controls[LIindex]["updated_at"] =
                                            utcTime;
                                    }
                                }
                            }
                        });
                    }
                    comments.forEach((comment, comment_index) => {
                        (comment?.comment_input_controls || []).forEach((CInput, comment_input_index) => {
                            if (CInput?.type == "Media") {
                                let commentMedia = CInput?.data || [];
                                for (let media in commentMedia) {
                                    if (commentMedia[media].uuid == mediaItem.uuid) {
                                        template.categories[category_index].lines[line_index].comments[
                                            comment_index
                                        ].comment_input_controls[comment_input_index].data[media]["is_deleted_from_rml"] =
                                            mediaItem?.is_deleted_from_rml == 1 || mediaItem?.is_deleted_from_rml == 2
                                                ? 3
                                                : mediaItem?.edited
                                                    ? 2
                                                    : 1;
                                        template.categories[category_index].lines[line_index].comments[
                                            comment_index
                                        ].comment_input_controls[comment_input_index].data[media]["updated_at"] = utcTime;
                                        if (commentMedia[media].edited == mediaItem.edited) {
                                            template.categories[category_index].lines[line_index].comments[
                                                comment_index
                                            ].comment_input_controls[comment_input_index].data[media].is_deleted = 1;
                                        }
                                        template.categories[category_index]["updated_at"] = utcTime;
                                        template.categories[category_index].lines[line_index].comments[
                                            comment_index
                                        ].comment_input_controls[comment_input_index]["updated_at"] = utcTime;
                                    }
                                }
                            }
                        });
                    });
                });
            });
            resolve(template);
        } catch (err) {
            console.log("err updateTemplate ===>", err);
            reject(false);
        }
    });
};

export default DeleteRMLMedia;