import { FormControl, Grid, TextField, TextareaAutosize } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import DoneIcon from '@mui/icons-material/Done';
import { filterPdfTemplateData } from "../../../Helpers/HelperFunctions";

function MultiLinesInputElement({
  element,
  elementType,
  templateId,
  pdfCategoriesType,
  level
}) {
  const [inspectionResponse, setInspectionResponse] = useState();
  const [multilineText, setMultilineText] = useState();
  const [inputChanged, setInputChanged] = useState(false);
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  let templateIndex =
    inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
  let prevData = inspectionResponse?.inspectionData?.data;
  delete prevData?.report_media_library_files;
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter(
    (item) => item?.merge_id == mergeId
  );

  useEffect(() => {
    let value = ElementpdfData?.map((item) => item?.value);
    setMultilineText(value);
  }, [inspectionResponse]);

  const HandleUpdateMulitLineTextApiCall = async () => {
    let formData = {
      type: "Input/Multiline",
      merge_id: mergeId,
      value: multilineText,
      updated_at: await GetUTCTime(),
    };
    if (
      pdfTemplate?.pdf_data?.length !== 0 &&
      pdfTemplate?.pdf_data?.length !== undefined
    ) {
      pdfTemplate.pdf_data.length = 0;
    }
    pdfTemplate?.pdf_data?.push(formData);
    let filteredData = pdfTemplate?.pdf_data?.filter(el=>el?.merge_id != mergeId)
    filteredData.push(formData)
    let filteredObject = filterPdfTemplateData(prevData, templateIndex,filteredData,mergeId);
    UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
  };

  useEffect(() => {
      setInspectionResponse(storeData);
  }, []);

  return (
    <>
      <Grid
        container
        className={`${
          elementType === "inner" || elementType === "layout"
            ? "pdf_container"
            : "pdf_inner_container"
        }`}
      >
        <Grid item xs={12}>
          <FormControl variant="standard" className={`forms-control w-100 ${level==3 ? "level3_class":""}`}>
            <label 
            className={pdfCategoriesType==1? (elementType !== "inner" && elementType !== "layout" ) || multilineText ==""  ?"inner_details_title":"inner_details_title_after_selection":"inner_details_title"}
            id="categoryName">
             {pdfCategoriesType==1? (elementType !== "inner" && elementType !== "layout" ) || multilineText ==""  ? "" : <DoneIcon sx={{marginLeft: "-28px",position: "absolute"}}/>:""}
              {element?.label}
            </label>
            <TextareaAutosize
              onBlur={inputChanged && HandleUpdateMulitLineTextApiCall}
              id="new_des_input"
              className="input-textfield"
              size="small"
              variant="outlined"
              type="text"
              minRows={3}
              style={{
                position: "relative",
                marginTop: "9px",
                maxWidth: "100%",
                lineHeight: "22px",
                overflow:"scroll",
                padding:"22px",
                fontSize:"16px"
              }}
              onChange={(e) => {
                setMultilineText(e?.target?.value);
                setInputChanged(true);
              }}
              value={multilineText}
              name="categoryName"
              // error={textError}
              // helperText={textErrorText}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default MultiLinesInputElement;
