import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {  Grid, MenuItem, Select } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import '../../../Assets/Styles/Containers/_button.scss';
import '../../../Assets/Styles/Containers/_inspection.scss';
import '../../../Assets/Styles/Containers/_lines.scss';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import { v4 as uuidv4 } from 'uuid';
import { UpdateTemplateDataSpecific } from '../../Helpers/ApiCall';
import { filterObjectsUptoLine } from '../../Helpers/HelperFunctions';

const CreateNewLineModal = ({showCreateNewLineModal,setShowCreateNewLineModal,setShowLineModal,categoryId,templateId,isInspDetails}) => {
    const dispatch = useDispatch();
    const [newLineName,setNewLineName] = useState('');
    const [newLineError,setNewLineError]=useState(false)
    const [selectedLineTypeError,setSelectedLineTypeError]=useState(false)
    const [newLineErrorText,setNewLineErrorText]=useState("")
    const responseData = useSelector((state) => state.CommonReducer);


    const handleClose=()=>{
        setShowCreateNewLineModal(false) 
        setShowLineModal(true);
        setNewLineError(false);
        setNewLineErrorText('')
    }

    const handleChange = (e) => {
        const newName = e.target.value;
        setNewLineName(newName);
      }

      const createNewLine=async(responseData,newCategoryName)=>{
        if(newCategoryName.trim() === ''){
           setNewLineError(true);
          setNewLineErrorText('Name cannot be blank')
        }
        else{  
          setNewLineError(false);
          setNewLineErrorText('')
          let indexValue = responseData?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id == templateId);
          let categoryIndex = responseData?.inspectionData?.data?.inspectiontemplates[indexValue]?.categories?.findIndex(itm => itm.id == categoryId);
        
          let prevData = responseData?.inspectionData?.data;       
          delete prevData?.report_media_library_files;
          let newLine = {
          uuid: uuidv4(),
          prompt: newLineName,
          defaultRating_id: 1,
          default_location: "",
          order: prevData?.inspectiontemplates[indexValue]?.categories[categoryIndex]?.lines?.length,
          is_deleted: 0,
          line_input_controls: [],
          comments: [],
          optional:0,
          updated_at: await GetUTCTime(),
          created_at: await GetUTCTime(),
        };

        if (prevData?.inspectiontemplates[indexValue]?.categories[categoryIndex]?.lines !==null) {
            prevData.inspectiontemplates[indexValue].categories[
              categoryIndex
            ].lines.push(newLine);
          } else {
            prevData.inspectiontemplates[indexValue].categories[
              categoryIndex
            ].lines = [newLine];
          }
          let filteredData = JSON.parse(JSON.stringify(prevData));
          let filteredTemplateObject = filteredData?.inspectiontemplates[indexValue];
          let filteredCategoryObject = filteredTemplateObject?.categories[categoryIndex];
          filteredCategoryObject.lines = [newLine];
          filteredTemplateObject.categories = [filteredCategoryObject];
          filteredData.inspectiontemplates = [filteredTemplateObject];
          UpdateTemplateDataSpecific(dispatch,prevData,filteredData);
          setShowCreateNewLineModal(false);
          setShowLineModal(false);
          setNewLineName('')
        }        
      }

  return (
    <Dialog className='dialog-container-new-category'
    open={showCreateNewLineModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
  <div className="close-wrapper-new-category">
      <ArrowBackIcon onClick={handleClose} />
    </div>
    <DialogTitle className='new-category-dialog-title'>{"Create new line"}</DialogTitle>   
    <DialogContent className="dialog-content-new-category">
      <Grid container className="input-controll">
        <Grid item xs={12} >
        <FormControl variant="standard" className="forms-control w-100">
              <label
                className="input-form-label"
                id="LineName"
              >
                Line name
              </label>
              <TextField id="legacy_user" className="input-textfield" size="small" variant="outlined"
                onChange={handleChange}
                sx={{ marginBottom: "15px" }}
                value={newLineName}
                name="LineName"
                error={newLineError}
                helperText={`${newLineError ? newLineErrorText : ""}`}
                placeholder="Name"
              />             
            </FormControl>

            {/* <FormControlLabel className='add_new_category_checkbox' control={<Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}/>} label="Save to template" /> */}
        </Grid>       

      </Grid>
    </DialogContent>
    <DialogActions className='new-category-modal-action'>          
      <Button className="add-category-btn" 
      onClick={()=>createNewLine(responseData,newLineName)}
       >
        Save
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default CreateNewLineModal