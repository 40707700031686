import { Autocomplete, Box, SvgIcon, FormControl, Grid, TextField, Chip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { arrowDown, copyIcon } from "../../../../Assets/Svg/Svg";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import HtmlEditor from "../../../Common/HtmlEditor/HtmlEditor";
import SunEditor from "suneditor-react";
import Checkbox from "@mui/material/Checkbox";
import CopySummaryModal from "./CopySummaryModal";
import FroalaEditorComponent from "../../../Common/HtmlEditor/FroalaEditorComponent";

function CLAddCommentModal(props) {
  const { commentTitle, setCommentTitle, commentTag, setCommentTag, commentSummaryTag, setCommentSummaryTag, commentText, setCommentText, commentSummary, setCommentSummary,lineData } = props;
  const storeData = useSelector((state) => state.CommonReducer);
  const [open, setOpen] = useState(false);
  const [summaryCopyModalOpen, setSummaryCopyModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [tag, setTag] = useState(storeData?.clTag !== undefined ? storeData?.clTag : []);
  const [summaryTag, setSummaryTag] = useState(storeData?.clSummaryTag !== undefined ? storeData?.clSummaryTag : []);
  const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "14px",
    },
  }));
  const editor = useRef();
  const commentEditor = useRef();
  // const commentData = lineData?.comments?.find((data) => data.uuid === uuid);

  const showClDropdown = storeData?.inspectionData?.data?.show_cl_dropdown;

  const handleCopyButton = () =>{
    if (commentText?.innerHTML?.length === 0 || commentText?.innerHTML === "<p><br></p>") {
      return null;
    } else if (commentSummary?.innerHTML?.length === 0 || commentSummary?.innerHTML === "<p><br></p>") {
      handleCopyComment();
    } else {
      setSummaryCopyModalOpen(true);
    }
  }

  async function handleCopyComment() {
    setCommentSummary(commentText);
    setSummaryCopyModalOpen(false);
    setCopied(true);
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleTagOpen = () => {
    setTagOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTagClose = () => {
    setTagOpen(false);
  };

  const handleChangeComment = (id,content) => {
    setCommentText((prevState) => ({ ...prevState, innerHTML: content }));
  };
  const handleChangeSumary = (id,content) => {
    setCommentSummary((prevState) => ({ ...prevState, innerHTML: content }));
    setCopied(false);
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === "click") {
      setTagOpen(!tagOpen);
    }
  };
  // commented the below code as we are not implementing create new tag feature with the current story
  // const CreateNewTagButton = (options) => {
  //     const classes = useStyles();
  //     const { containerProps, children } = options;
  //     return (
  //         <Paper className={classes.paper} {...containerProps} >
  //             <Button className="add-select" sx={{ textTransform: 'none',margin: "0px 0px 0px 18px" }}>
  //                 <span style={{ fontSize:"18px" }} >+ </span> <span style={{marginLeft:"12px" , fontSize:"18px"}} >Create new tag</span>
  //             </Button>
  //             <Divider />
  //             {children}
  //         </Paper>
  //     );
  // };
  // commented the below code as we are not implementing create new tag feature with the current story
  // const CreateNewSummarySectionButton = (options) => {
  //     const classes = useStyles();
  //     const { containerProps, children } = options;
  //     return (
  //         <Paper className={classes.paper} {...containerProps} >
  //             <Button className="add-select" sx={{ textTransform: 'none',margin: "6px 0px 6px 22px" }}>
  //             <span style={{ fontSize:"18px" }} >+ </span> <span style={{marginLeft:"12px" , fontSize:"18px"}} >Create new summary</span>
  //             </Button>
  //             <Divider />
  //             {children}
  //         </Paper>
  //     );
  // };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Grid item xs={12} style={{ marginTop: "24px" }}>
        <FormControl variant="standard" className="forms-control">
          <label className="cl-new-comment-input-form-label" htmlFor="title">
            TITLE
          </label>
          <TextField id="cl_title" className="input-textfield" size="small" variant="outlined" value={commentTitle} onChange={(e) => setCommentTitle(e.target.value)} type="text" sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#ccc", 
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#ccc", 
                              },
                            },
                          }}/>
        </FormControl>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6.5} className="cl-add-comment-sections cl-editor cl_comment_editor">
          <Grid container className="input-controll">
            <Grid item xs={12} sm={12}>
              <FormControl variant="standard" className="forms-control">
                <label className="cl-new-comment-input-form-label" htmlFor="title">
                  TAGS
                </label>
                {/* tag selection */}
                <Autocomplete
                  className="forms-control-tag custom-color autocompleteCustom"
                  id="tags-outlined"
                  multiple
                  size="small"
                  options={tag}
                  onClick={!tagOpen ? handleTagOpen : handleTagClose}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.name}
                  open={tagOpen}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc", 
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc", 
                      },
                    },
                  }}
                  onChange={(e, value) => {
                    setCommentTag(value);
                  }}
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseOutlinedIcon />} label={option?.name} {...getTagProps({ index })} sx={{
                    padding: '2px 2px 2px 10px !important',
                    fontSize:"10px !important",
                }}/>)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                      <Box
                        sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
                        style={{
                          marginRight: 8,
                        }}
                      />
                      {option?.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={commentTag?.length === 0 ? "Add tags" : ""}
                      onClick={!tagOpen ? handleTagOpen : handleTagClose}
                      onBlur={handleTagClose}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400, paddingLeft: commentTag?.length !== 0 ? "0px" : "20px" },
                        endAdornment: (
                          <>
                            <SearchIcon className={`customSearch ${commentTag?.length !== 0 ? "hidden" : ""}`} style={{ color: "#9b9b9b", marginRight: "8px" }} />
                            <SvgIcon className={`customArrowDown ${tagOpen ? "arrowActive" : ""}`} viewBox="" onClick={!tagOpen ? handleTagOpen : handleTagClose}>
                              {arrowDown}
                            </SvgIcon>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant="standard" className="forms-control">
                <label className="cl-new-comment-input-form-label" htmlFor="Recommendation" style={{ marginBottom: "9px" }}>
                  COMMENT
                </label>
                <FroalaEditorComponent editorData={commentText} editor={commentEditor} id={"commentText"} onChange={handleChangeComment} minHeight={100} maxHeight={110} 
                // commentData={commentData} 
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5.5} className="cl-add-comment-sections-summary cl-editor">
          <Grid container className="input-controll">
            <Grid item xs={12} sm={12}>
              <FormControl variant="standard" className="forms-control">
                <label className="cl-new-comment-input-form-label" htmlFor="title">
                  SUMMARY SECTION
                </label>
                <Autocomplete
                  className="forms-control1 custom-color autoHover autocompleteSummaryCustom"
                  id="tags-outlined"
                  multiple
                  size="small"
                  options={summaryTag}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.name}
                  openOnFocus
                  open={open}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc", 
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc", 
                      },
                    },
                  }}
                  onChange={(e, value) => {
                    setCommentSummaryTag(value);
                  }}
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip className="custom-chip" variant="outlined" deleteIcon={<CloseOutlinedIcon />} 
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "8px" }}
                      />
                      <div style={{fontSize:"14px"}}>{option?.name}</div>
                    </Box>
                  } {...getTagProps({ index })} sx={{
                    padding: '2px 2px 2px 10px !important',
                    fontSize:"10px !important",
                }}/>)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ borderRadius: "50%" }} icon={icon} checkedIcon={checkedIcon} checked={selected} />
                      <Box
                        sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
                        style={{
                          marginRight: 8,
                        }}
                      />
                      {option?.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={commentSummaryTag?.length === 0 ? "No summary selected" : ""}
                      onClick={!open ? handleOpen : handleClose}
                      onBlur={handleClose}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400 },
                        endAdornment: (
                          <>
                            <SvgIcon className={`customArrowDown ${open ? "arrowActive" : ""}`} viewBox="" onClick={!open ? handleOpen : handleClose}>
                              {arrowDown}
                            </SvgIcon>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant="standard" className="forms-control">
                <label className="cl-new-comment-input-form-label summary-section-copy-icon" htmlFor="Recommendation">
                  SUMMARY COMMENT
                    <IconButton 
                    onClick={handleCopyButton}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    >
                      <SvgIcon viewBox="">
                        {copyIcon}
                      </SvgIcon>
                    </IconButton>
                </label>
                <FroalaEditorComponent editorData={commentSummary} editor={editor} id={"summaryText"} onChange={handleChangeSumary} minHeight={100} maxHeight={110} copy={copied} 
                // commentData={commentData} 
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* below code is commented due to design change */}
      {/* <input
                type="file"
                accept="image/*, video/*, audio/*"
                //   onChange={handleMediaUpload}
                style={{ display: 'none' }}
                id="media-upload"
            />
            <label htmlFor="media-upload">
                <Button variant="outlined" component="span" className='comment_library_upload'>
                    <MdOutlineUploadFile sx={{ marginRight: "10px" }} />   Upload media
                </Button>
            </label> */}
        {summaryCopyModalOpen && !showClDropdown && <CopySummaryModal summaryCopyModalOpen={summaryCopyModalOpen} setSummaryCopyModalOpen={setSummaryCopyModalOpen} handleCopyComment={handleCopyComment} />}
    </>
  );
}

export default CLAddCommentModal;
