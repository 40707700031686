import { Grid, Typography, Divider } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddNewDescriptionModal from "../Description/AddNewDescriptionModal";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import Preloader from "../../../Helpers/Preloader";
import EditDescription from "../Description/EditDescription";
import { filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";

const AdditionalInput = ({ data, templateId, categoryId, lineUuid }) => {
  const [showAddDescModal, setShowAddDesModal] = useState(false);
  const [editDescriptionModal, setEditDescriptionModal] = useState(false);
  const [descriptionData, setDescriptionData] = useState();
  const [inspectionResponse, setInspectionResponse] = useState();
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // redux
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  const handleClick = async (e, item) => {
    setLoader(true);
    let decValueData = descriptionData.values;
    let curentItemIndex = decValueData.findIndex((el) => el.value === item.value);
    if (curentItemIndex !== -1) {
      decValueData[curentItemIndex].is_selected = !decValueData[curentItemIndex].is_selected;
      decValueData[curentItemIndex].updated_at = await GetUTCTime();
    }

    var Templates = inspectionResponse?.inspectionData?.data?.inspectiontemplates;
    var indexValue = Templates?.findIndex((itm) => itm.template_id === templateId);
    var categoryIndex = Templates[indexValue]?.categories?.findIndex((itm) => itm.id === categoryId);
    var lineIndex = Templates[indexValue]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let prevData = inspectionResponse?.inspectionData?.data;
    delete prevData.report_media_library_files;
    let AdditionalInputControlIndex = Templates[indexValue]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex((itm) => {
      return itm.type === "Additional Input" && itm.line_input_control_id == data?.line_input_control_id;
    });

    if (AdditionalInputControlIndex >= 0) {
      prevData.inspectiontemplates[indexValue].categories[categoryIndex].lines[lineIndex].line_input_controls[AdditionalInputControlIndex].values = decValueData;
      prevData.inspectiontemplates[indexValue].categories[categoryIndex].lines[lineIndex].line_input_controls[AdditionalInputControlIndex].updated_at = await GetUTCTime();
    }
    let filteredObject = filterObjectsUptoLine(prevData, indexValue, categoryIndex, lineIndex);
    setRefresh((prev) => !prev);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  useEffect(() => {
    setDescriptionData(data);
  }, [data]);

  useEffect(() => {
    if (storeData.inspectionData?.data) {
      setLoader(false);
      setInspectionResponse(storeData);
    }
  }, [storeData.inspectionData?.data]);

  return (
    <>
      <Preloader showPreloader={loader} />
      <AddNewDescriptionModal
        showAddDescModal={showAddDescModal}
        setShowAddDesModal={setShowAddDesModal}
        descriptionData={descriptionData}
        inspectionResponse={inspectionResponse}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        isAdditionalInfo={true}
        currentAdditionalInputID={data?.line_input_control_id}
      />
      {editDescriptionModal && (
        <EditDescription
          setEditDescriptionModal={setEditDescriptionModal}
          setDescriptionData={setDescriptionData}
          editDescriptionModal={editDescriptionModal}
          descriptionData={descriptionData}
          setShowAddDesModal={setShowAddDesModal}
          data={data}
          showAddDescModal={showAddDescModal}
          inspectionResponse={inspectionResponse}
          templateId={templateId}
          categoryId={categoryId}
          lineUuid={lineUuid}
          isAdditionalInfo={true}
          currentAdditionalInputID={data?.line_input_control_id}
        />
      )}
      <Grid container className="container">
        <Grid item xs={12} className="line_detail_des_title">
          <span className="details_title">{data?.prompt}</span>
          {/* style given as visibility hidden for now as the edit functionality is not yet implementeed..remove the style once working on the edit flow */}
          <span className="details_action" id="edit_additional_description" onClick={() => setEditDescriptionModal(true)}>
            <EditIcon />
            Edit
          </span>
        </Grid>
        {descriptionData?.values.map((item, index) => (
          <Grid item xs={6} md={storeData?.reportSideBarActive ? 6 : 4} key={index}>
            <FormGroup>
              <FormControlLabel
                className="checkbox flex_start"
                control={
                  <Checkbox
                    sx={{
                      height: "37.5px !important",
                      width: "37.5px !important",
                      "& .MuiSvgIcon-root": { fontSize: "20px !important" },
                    }}
                    checked={data?.data?.includes(item.value) ? true : false}
                    onClick={(e) => handleClick(e, item)}
                  />
                }
                label={item.value}
              />
            </FormGroup>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography className="line_details__add_btn" id="add_additional_description" onClick={() => setShowAddDesModal(true)}>
            <AddCircleIcon />
            <span className="line_details__add_text">Add new </span>{" "}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalInput;
