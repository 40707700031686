import { FormControl, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { useDispatch } from "react-redux";
import DoneIcon from '@mui/icons-material/Done';
import { filterPdfTemplateData } from "../../../Helpers/HelperFunctions";

function TextElement({ element, elementType, templateId,pdfCategoriesType,level}) {
  const [inspectionResponse, setInspectionResponse] = useState();
  const [apiCallDone, setApiCallDone] = useState(false);
  const [text, setText] = useState();
  const [inputChanged, setInputChanged] = useState(false);
  const [textError, setTextError] = useState(false);
  const [textErrorText, setTextErrorText] = useState("");
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
  let prevData = storeData?.inspectionData?.data;
  delete prevData?.report_media_library_files;
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter((item) => item?.merge_id == mergeId);
  let value = ElementpdfData?.[0]?.value;
  let newPrevData = JSON?.parse(JSON?.stringify(storeData?.inspectionData?.data))
  let newPdfTemplate = newPrevData?.inspectiontemplates[templateIndex];
 
  const HandleUpdateTextApiCall = async () => {
    let formData = {
      type: "Input/Text",
      merge_id: mergeId,
      value: text,
      updated_at: await GetUTCTime(),
    };
    if (
      pdfTemplate?.pdf_data?.length !== 0 &&
      pdfTemplate?.pdf_data?.length !== undefined
    ) {
      pdfTemplate.pdf_data.length = 0;
    }
    pdfTemplate?.pdf_data?.push(formData);
    newPdfTemplate?.pdf_data?.push(formData);    
    let filteredData = pdfTemplate?.pdf_data?.filter(el=>el?.merge_id != mergeId)
    filteredData.push(formData)
    let filteredObject = filterPdfTemplateData(prevData, templateIndex,filteredData,mergeId);
    UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
    setApiCallDone(true)
  };

  // useEffect(() => {
  //   if(apiCallDone) setInspectionResponse(storeData);
  // }, [apiCallDone]);

  // useEffect(() => {
  //   setText(value == undefined && value == null ? "" : value);
  // }, [inspectionResponse]);

  useEffect(() => {
    setText(value == undefined && value == null ? "" : value);
  }, [inspectionResponse]);
  
  useEffect(() => {
    setInspectionResponse(storeData);
  }, []);

  return (
    <>
      <Grid container className={`${elementType === "inner" || elementType === "layout" ? "pdf_container" : "pdf_inner_container"}`}>
        <Grid item xs={12}>
          <FormControl variant="standard" className={`forms-control w-100 ${level==3 ? "level3_class":""}`}>
            <label 
            className={pdfCategoriesType==1? (elementType !== "inner" && elementType !== "layout" ) || text ==""  ?"inner_details_title":"inner_details_title_after_selection":"inner_details_title"}
            id="categoryName">
            {pdfCategoriesType==1? (elementType !== "inner" && elementType !== "layout" ) || text ==""  ? "" : <DoneIcon sx={{marginLeft: "-28px",position: "absolute"}}/>:""}
             {element?.label}
            </label>
            <TextField
              onBlur={inputChanged && HandleUpdateTextApiCall}
              id="new_des_input"
              className="input-textfield pdfInputBox"
              size="small"
              variant="outlined"
              type="text"
              sx={{
                position: "relative",
                marginTop: "9px",
                minWidth: "100%",
                height: "40px",
              }}
              onChange={(e) => {
                setText(e?.target?.value);
                setInputChanged(true);
              }}
              value={text}
              name="categoryName"
              error={textError}
              helperText={textErrorText}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default TextElement;
