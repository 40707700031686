import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { v4 as uuidv4 } from 'uuid';
import {  Grid } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import '../../../../Assets/Styles/Containers/_button.scss';
import '../../../../Assets/Styles/Containers/_inspection.scss';
import { GetUTCTime } from '../../../Helpers/GetUTCTime';
import { UpdateTemplateDataSpecific } from '../../../Helpers/ApiCall';
import { filterObjectsUptoLine } from '../../../Helpers/HelperFunctions';

const AddNewDescriptionModal = ({showAddDescModal,setShowAddDesModal,descriptionData,inspectionResponse,templateId,categoryId,lineUuid,isAdditionalInfo,currentAdditionalInputID,setEditDescriptionModal,editDescriptionModal,addToList,data}) => {
    const [descriptionName,setDescriptionName] = useState(''); 
    const [descriptionError,setDescriptionError] = useState(false); 
    const [descriptionErrorMessage,setDescriptionErrorMessage] = useState(''); 
     // redux
  const dispatch = useDispatch();
  const handleClose=()=>{
        setShowAddDesModal(false);
        setDescriptionError(false);
        setDescriptionErrorMessage('');
        setDescriptionName('');
  }

  const handleEditClose=()=>{
    setEditDescriptionModal(true)
    setShowAddDesModal(false);
    setDescriptionErrorMessage('');
    setDescriptionName('');
  }

  const createNewDescription=async(inspectionResponse,descriptionName)=>{
    let availiableDescriptions = descriptionData?.values?.map(val=>val.value);
    let isPresent = availiableDescriptions?.some(item => item.toLowerCase()?.trim() === descriptionName?.toLowerCase()?.trim())
    if(isPresent){
      setDescriptionError(true);
      setDescriptionErrorMessage('This description is already on the list.')
    }
    else if(descriptionName.trim()===""){
      setDescriptionError(true);
      setDescriptionErrorMessage('Please add a description')
    }
    else{
      setDescriptionError(false);
      setDescriptionErrorMessage('');
      if(isAdditionalInfo){
        var Templates = inspectionResponse?.inspectionData?.data?.inspectiontemplates;
        var indexValue = Templates?.findIndex(itm => itm.template_id === templateId);
        var categoryIndex = Templates[indexValue]?.categories?.findIndex(itm => itm.id === categoryId);
        var lineIndex = Templates[indexValue]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid);
        let prevData = inspectionResponse?.inspectionData?.data;
        delete prevData?.report_media_library_files;
          let AdditionalInputControlIndex = Templates[indexValue]?.categories[
              categoryIndex
          ]?.lines[lineIndex]?.line_input_controls?.findIndex((itm) => {
              return (
                  itm.type === "Additional Input" &&
                  itm.line_input_control_id == currentAdditionalInputID
              );
          });
          if (AdditionalInputControlIndex >= 0) {
           
          prevData.inspectiontemplates[indexValue].categories[categoryIndex
          ].lines[lineIndex].line_input_controls[AdditionalInputControlIndex].values.push({
            value: descriptionName,
            is_selected: true,
            updated_at: await GetUTCTime(),
            is_deleted: 0,
            uuid: uuidv4(),
          });
          prevData.inspectiontemplates[indexValue].categories[categoryIndex
          ].lines[lineIndex].line_input_controls[AdditionalInputControlIndex].data.push(descriptionName)
          prevData.inspectiontemplates[indexValue].categories[categoryIndex
          ].lines[lineIndex].line_input_controls[AdditionalInputControlIndex].updated_at = await GetUTCTime()
          }
          let filteredObject = filterObjectsUptoLine(prevData, indexValue, categoryIndex, lineIndex);
          UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
          setShowAddDesModal(false);
          setDescriptionName('');
      }
      else{
      let templateIndex =  inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
      let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id === categoryId);
      let lineIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid) 
      let linIpIdx = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(lineInpt=>lineInpt.type === "Description")
      let prevData = inspectionResponse?.inspectionData?.data;
      delete prevData?.report_media_library_files;
      if (linIpIdx < 0) {
        let discriptionObject = {
          line_input_control_id: null,
          uuid: uuidv4(),
          prompt: "Description",
          values: [
            {
              value: descriptionName,
              is_selected: "true",
              updated_at: await GetUTCTime(),
              is_deleted: 0,
              uuid: uuidv4(),
            },
          ],
          type: "Description",
          data: [descriptionName],
          updated_at: await GetUTCTime(),
          created_at: await GetUTCTime(),
        };

        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls.push(discriptionObject);
      } else {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[
          linIpIdx
        ].values.push({
          value: descriptionName,
          is_selected: "true",
          is_deleted: 0,
          uuid: uuidv4(),
          updated_at: await GetUTCTime(),
        });

        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[
          linIpIdx
        ].data.push(descriptionName);

        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[
          linIpIdx
        ].updated_at = await GetUTCTime();
      }
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
      setShowAddDesModal(false);
      setDescriptionName('');
      }
    }
  }
  return (
    <Dialog className='dialog-container-new-option'
    open={showAddDescModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
   <div className="close-wrapper-new-category">
      <ArrowBackIcon onClick={handleClose}/>
    </div>
    <DialogTitle className='new-category-dialog-title'>{"Add new option"}</DialogTitle>
   
    <DialogContent className="dialog-content-new-option">
      <Grid container className="input-controll">
        <Grid item xs={12} >
        <FormControl variant="standard" className="forms-control w-100">
              <label
                className="input-form-label"
                id="categoryName"
              >
                {data === undefined ? "Description" : (data?.prompt === null ? "" : data?.prompt)}
              </label>
              <TextField id="legacy_user" className="input-textfield" size="small" variant="outlined"
                type="text"
                onChange={(e)=>setDescriptionName(e.target.value)}
                value={descriptionName}
                name="categoryName"
                error={descriptionError}
                helperText={descriptionErrorMessage}
              />
            </FormControl>

            {/* <FormControlLabel className='add_new_category_checkbox' control={<Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}/>} label="Save to template" /> */}
        </Grid>

      </Grid>
    </DialogContent>
    <DialogActions className='new-category-modal-action'>          
      <Button className="add-category-btn" 
      onClick={()=>createNewDescription(inspectionResponse,descriptionName)}
       >
        Save
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default AddNewDescriptionModal