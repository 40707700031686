import { Grid, Typography, Divider } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddNewDescriptionModal from "./AddNewDescriptionModal";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import Preloader from "../../../Helpers/Preloader";
import EditDescription from "./EditDescription";
import { filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";

const Description = ({ data, templateId, categoryId, lineUuid }) => {
  const [showAddDescModal, setShowAddDesModal] = useState(false);
  const [descriptionData, setDescriptionData] = useState();
  const [inspectionResponse, setInspectionResponse] = useState();
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editDescriptionModal, setEditDescriptionModal] = useState(false);
  // redux
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  const handleClick = async (e, item) => {
    setLoader(true);
    let decValueData = descriptionData.values;
    let curentItemIndex = decValueData.findIndex((el) => el.value === item.value);
    if (curentItemIndex !== -1) {
      decValueData[curentItemIndex].is_selected = !decValueData[curentItemIndex].is_selected;
      decValueData[curentItemIndex].updated_at = await GetUTCTime();
    }
    let templateIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let linIpIdx = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(
      (lineInpt) => lineInpt.type === "Description"
    );
    let responseData = inspectionResponse?.inspectionData?.data;
    if (linIpIdx !== -1) {
      responseData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[linIpIdx].values = decValueData;
      responseData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[linIpIdx].updated_at = await GetUTCTime();
    }
    let prevData = inspectionResponse?.inspectionData?.data;
    delete prevData?.report_media_library_files;
    let filteredObject = await filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    await UpdateTemplateDataSpecific(dispatch, responseData, filteredObject);
    setLoader(false);
  };

  useEffect(() => {
    if (data == undefined) setDescriptionData();
    if (data) {
      let finalArray = [];
      let pullData = data;
      for (let item of pullData?.values) {
        if (item?.is_deleted == undefined) {
          item.is_deleted = 0;
        }
        finalArray.push(item);
      }
      pullData.values = finalArray;
      setDescriptionData(pullData);
    }
  }, [data]);

  useEffect(() => {
    if (storeData.inspectionData?.data) {
      setInspectionResponse(storeData);
      setLoader(false);
    }
  }, [storeData.inspectionData?.data]);

  return (
    <>
      <Preloader showPreloader={loader} />
      <AddNewDescriptionModal
        showAddDescModal={showAddDescModal}
        setShowAddDesModal={setShowAddDesModal}
        descriptionData={descriptionData}
        editDescriptionModal={editDescriptionModal}
        setEditDescriptionModal={setEditDescriptionModal}
        inspectionResponse={inspectionResponse}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        isAdditionalInfo={false}
        currentAdditionalInputID={null}
        data={data}
      />

      {editDescriptionModal && (
        <EditDescription
          setEditDescriptionModal={setEditDescriptionModal}
          setDescriptionData={setDescriptionData}
          editDescriptionModal={editDescriptionModal}
          descriptionData={descriptionData}
          setShowAddDesModal={setShowAddDesModal}
          data={data}
          showAddDescModal={showAddDescModal}
          isAdditionalInfo={false}
          inspectionResponse={inspectionResponse}
          templateId={templateId}
          categoryId={categoryId}
          lineUuid={lineUuid}
        />
      )}
      {data?.is_disabled != 1 ? (
        <Grid container className="container">
          <Grid item xs={12} className="line_detail_des_title">
            <span className="details_title">{data === undefined ? "Description" : data?.prompt === null ? "" : data?.prompt}</span>
            <span className="details_action" id="edit_description" onClick={() => setEditDescriptionModal(true)}>
              <EditIcon />
              Edit
            </span>
          </Grid>
          {descriptionData?.values
            ?.filter((item) => item?.is_deleted == 0)
            ?.map((item, index) => (
              <Grid item xs={6} md={storeData?.reportSideBarActive ? 6 : 4} key={index}>
                <FormGroup>
                  <FormControlLabel
                    className="checkbox flex_start"
                    control={
                      <Checkbox
                        sx={{
                          height: "37.5px !important",
                          width: "37.5px !important",
                          "& .MuiSvgIcon-root": { fontSize: "20px !important" },
                        }}
                        checked={data?.data?.includes(item.value) ? true : false}
                        onClick={(e) => handleClick(e, item)}
                      />
                    }
                    label={item.value}
                  />
                </FormGroup>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Typography className="line_details__add_btn" id="add_new_description" onClick={() => setShowAddDesModal(true)}>
              <AddCircleIcon />
              <span className="line_details__add_text">Add new </span>{" "}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default Description;
