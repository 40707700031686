import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import ReorderIcon from "@mui/icons-material/Reorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Divider,
  FormControl,
  Grid,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, Modal } from "@mui/material";
import FilledButton from "../../Button/FilledButton";
import "../../../../Assets/Styles/Containers/_modal.scss";
import { v4 as uuidv4 } from "uuid";
// css import
import "../../../../Assets/Styles/Containers/_button.scss";
import "../../../../Assets/Styles/Containers/_inspection.scss";
import { checkCategoryPresent, filterObjectsUptoCategory, filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific, UpdateTemplateMediaDataSpecific } from "../../../Helpers/ApiCall";
import toast from "react-hot-toast";
import OutlinedButton from "../../Button/OutlinedButton";
import DeviceDetector from "device-detector-js";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { updateInspectionTemplateDataOffline } from "../../../Redux/Actions/CommonActions";
import Preloader from "../../../Helpers/Preloader";
import Tooltip from "@mui/material/Tooltip";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ReorderMedia from "./ReorderMedia";
import update from "immutability-helper";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import DeleteConfirmationDialog from "../../../Common/Alert/DeleteConfirmationDialog";

const MediaGalleryModal = ({
  data,
  showMediaGalleryModal,
  setRefresh,
  setShowMediaGalleyModal,
  action,
  templateId,
  categoryUuid,
  lineUuid,
  commentUuid,
  categoryId,
  loader,
  setLoader,
  setImage,
  handleClick,
  open,
  handleClose,
  image,
  fileInput,
  anchorEl,
  setAnchorEl,
  handleAddMedia,
  editFileInput,
  dataName,
  title,
}) => {
  const [inspectionData, setInspectionData] = useState();
  const [deleteMedia, setDeleteMedia] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [reOrderMedia, setReOrderMedia] = useState(false);
  const [captionChanged, setCaptionChanged] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [inspectionResponse, setInspectionResponse] = useState();
  const [currentMedia, setCurrentMedia] = useState();
  const [activeDeleteMedia, setActiveDeleteMedia] = useState({});
  const [caption, setCaption] = useState();
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const deviceDetector = new DeviceDetector();
  const userAgent =
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
  const device = deviceDetector.parse(userAgent);
  const [mediaData, setMediaData] = useState([]);
  const [afterReodermediaData, setAfterReorderMediaData] = useState([]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleAddMedia(acceptedFiles, "drag");
    },
    [handleAddMedia]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    setMediaData(data);
  }, [data]);
  const handleCloseDel = () => {
    setReOrderMedia(false);
    setShowMediaGalleyModal(false);
    setRefresh((prev) => !prev);
    setImage("");
    setDeleteMedia(false);
  };
  const handleDeleteAction = () => {
    setDeleteMedia(false);
  };
  const handleCloseDeleteAlert = () => {
    setDeleteAlert(false);
  };
  const deleteClickedMedia = (media) => {
    setDeleteAlert(true);
    setActiveDeleteMedia(media);
  };

  const handleCaption = (image) => {
    setShowDescriptionModal(true);
    setCaption(image.description);
    setCurrentMedia(image);
  };
  const handleCloseDescriptionModal = () => {
    // setRefresh(prev=>!prev);
    setCaptionChanged(false);
    setShowDescriptionModal(false);
  };

  const handlemediaDesc = async () => {
    if (action === "comment") {
      let templateIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
          (itm) => itm.template_id === templateId
        );
      let categoryIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories?.findIndex((itm) => itm.id === categoryId);
      let lineIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines?.findIndex(
          (line) => line.uuid === lineUuid
        );
      let linIpIdx =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[
          lineIndex
        ]?.line_input_controls?.findIndex(
          (lineInpt) => lineInpt.type === "Media"
        );
      let commentIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
          (itm) => itm.uuid == commentUuid
        );
      let mediaIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
          commentIndex
        ]?.comment_input_controls?.findIndex((itm) => itm.type == "Media");
      let imageIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
          commentIndex
        ]?.comment_input_controls[mediaIndex]?.data.findIndex(
          (img) => img.id === currentMedia?.id
        );
      let prevData = inspectionResponse?.inspectionData?.data;
      delete prevData?.report_media_library_files;
      if (mediaIndex !== -1) {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          mediaIndex
        ].updated_at = await GetUTCTime();

        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          mediaIndex
        ].data[imageIndex].description = caption;
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          mediaIndex
        ].data[imageIndex].updated_at = await GetUTCTime();
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
        setShowDescriptionModal(false);
      }
    } else {
      let templateIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
          (itm) => itm.template_id === templateId
        );
      let categoryIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories?.findIndex((itm) => itm.id === categoryId);
      let lineIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines?.findIndex(
          (line) => line.uuid === lineUuid
        );
      let linIpIdx =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[
          lineIndex
        ]?.line_input_controls?.findIndex(
          (lineInpt) => lineInpt.type === "Media"
        );
      let imageIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls[
          linIpIdx
        ]?.data.findIndex((img) => img.id === currentMedia?.id);
      let prevData = inspectionResponse?.inspectionData?.data;
      delete prevData?.report_media_library_files;
      if (linIpIdx !== -1) {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[linIpIdx].updated_at =
          await GetUTCTime();

        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[linIpIdx].data[
          imageIndex
        ].description = caption;
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[linIpIdx].data[
          imageIndex
        ].updated_at = await GetUTCTime();
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
        setShowDescriptionModal(false);
        setCaptionChanged(false);
      }
    }
    setRefresh((prev) => !prev);
  };

  const handleConfirmClickedMediaDelete = async () => {
    setLoader(true);
    if (action === "comment") {
      let templateIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
          (itm) => itm.template_id === templateId
        );
      let categoryIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories?.findIndex((itm) => itm.id === categoryId);
      let lineIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines?.findIndex(
          (line) => line.uuid === lineUuid
        );
      let linIpIdx =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[
          lineIndex
        ]?.line_input_controls?.findIndex(
          (lineInpt) => lineInpt.type === "Media"
        );
      let commentIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
          (itm) => itm.uuid == commentUuid
        );
      let mediaIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
          commentIndex
        ]?.comment_input_controls?.findIndex((itm) => itm.type == "Media");
      let imageIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
          commentIndex
        ]?.comment_input_controls[mediaIndex]?.data.findIndex(
          (img) => img.id === activeDeleteMedia?.id
        );
      let prevData = inspectionResponse?.inspectionData?.data;
      delete prevData?.report_media_library_files;
      if (mediaIndex !== -1) {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          mediaIndex
        ].updated_at = await GetUTCTime();

        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          mediaIndex
        ].data[imageIndex].is_deleted = 1;
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          mediaIndex
        ].data[imageIndex].updated_at = await GetUTCTime();
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateMediaDataSpecific(dispatch,prevData,filteredObject);
        setDeleteAlert(false);
      }
    } else {
      let templateIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
          (itm) => itm.template_id === templateId
        );
      let categoryIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories?.findIndex((itm) => itm.id === categoryId);
      let lineIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines?.findIndex(
          (line) => line.uuid === lineUuid
        );
      let linIpIdx =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[
          lineIndex
        ]?.line_input_controls?.findIndex(
          (lineInpt) => lineInpt.type === "Media"
        );
      let imageIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls[
          linIpIdx
        ]?.data.findIndex((img) => img.id === activeDeleteMedia?.id);
      let prevData = inspectionResponse?.inspectionData?.data;
      delete prevData?.report_media_library_files;
      if (linIpIdx !== -1) {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[linIpIdx].updated_at =
          await GetUTCTime();

        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[linIpIdx].data[
          imageIndex
        ].is_deleted = 1;
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[linIpIdx].data[
          imageIndex
        ].updated_at = await GetUTCTime();
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateMediaDataSpecific(dispatch,prevData,filteredObject);
        setDeleteAlert(false);
      }
    }
  };

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
    }
  }, [storeData.inspectionData, data]);

  useEffect(() => {
    setInspectionData(storeData.inspectionData?.data);
  }, [storeData.inspectionData]);

  // reorder
  const reorderApicall = async (result) => {
    let templateIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
        (itm) => itm.template_id === templateId
      );
    let categoryIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines?.findIndex(
        (line) => line.uuid === lineUuid
      );
    let linIpIdx =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines[
        lineIndex
      ]?.line_input_controls?.findIndex(
        (lineInpt) => lineInpt.type === "Media"
      );
    let commentIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
        (itm) => itm.uuid == commentUuid
      );
    let mediaIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
        commentIndex
      ]?.comment_input_controls?.findIndex((itm) => itm.type == "Media");
    let prevData = inspectionResponse?.inspectionData?.data;
    delete prevData?.report_media_library_files;
    if (action === "comment") {
      result.map(async (item, index) => {
        item.updated_at = await GetUTCTime();
        item.order = index;
      });
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].comments[commentIndex].comment_input_controls[
        mediaIndex
      ].data = result;
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].comments[commentIndex].comment_input_controls[
        mediaIndex
      ].updated_at = await GetUTCTime();
    } else {
      result.map(async (item, index) => {
        item.updated_at = await GetUTCTime();
        item.order = index;
      });
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[linIpIdx].updated_at =
        await GetUTCTime();
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].updated_at = await GetUTCTime();
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].updated_at = await GetUTCTime();
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[linIpIdx].data = result;
    }
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex,lineIndex);
    UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
  };

  // reorder
  const moveCard = (dragIndex, hoverIndex) => {
    setMediaData((prevCards) => {
      return update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      });
    });
    setAfterReorderMediaData(
      update(mediaData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, mediaData[dragIndex]],
        ],
      })
    );
  };

  const renderCard = (image, index) => {
    return (
      <ReorderMedia
        handleCaption={handleCaption}
        reorderApicall={reorderApicall}
        afterReodermediaData={afterReodermediaData}
        inspectionData={inspectionData}
        reOrderMedia={reOrderMedia}
        key={image.id}
        index={index}
        id={image.id}
        item={image}
        moveCard={moveCard}
      />
    );
  };

  // const updateOnReorder=(list)=>{
  //  reorderApicall(list)
  // }

  return (
    <>
      {/* caption */}
      <Modal
        open={showDescriptionModal}
        onClose={handleCloseDescriptionModal}
        BackdropProps={{ style: { opacity: "60%" } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`mark-as-complete-modal-main`}
      >
        <Box
          className="mark-as-complete-modal"
          sx={{ minHeight: "211px !important", top: "40% !important" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon
                sx={{
                  float: "right",
                  cursor: "pointer",
                  color: "#A5A5A5",
                  mt: 1,
                  mr: 1,
                }}
                onClick={handleCloseDescriptionModal}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <p className="modal-heading">Add caption </p>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                textAlign: "center",
                mt: "14px",
                justifyContent: "center",
              }}
            >
              <p className="modal-content">This will appear in the report.</p>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                textAlign: "center",
                mt: "20px",
                justifyContent: "center",
              }}
            >
              <FormControl variant="standard" className="forms-control_caption">
                <label
                  className="input-form-label text-left"
                  htmlFor="statement"
                  id="demo-simple-select-label"
                >
                  Caption
                </label>
                <TextareaAutosize
                  className="textArea"
                  id="statement"
                  aria-label="minimum height"
                  minRows={4}
                  placeholder=""
                  style={{ width: "100%", resize: "none" }}
                  onChange={(e) => {
                    setCaption(e.target.value);
                    setCaptionChanged(true);
                  }}
                  value={caption}
                  InputProps={{
                    inputComponent: TextareaAutosize,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                textAlign: "center",
                mt: "24px",
                justifyContent: "center",
                gap: "20px",
                mb: "10px",
              }}
            >
              {/* <OutlinedButton width="150px" text="cancel" onClick={()=>setDeleteAlert(false)}/> */}
              <FilledButton
                width="150px"
                text="save"
                disabled={!captionChanged}
                onClick={() => handlemediaDesc()}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* caption end */}

      {/* delete alert dialog */}
      <DeleteConfirmationDialog
        open={deleteAlert}
        handleClose={handleCloseDeleteAlert}
        title={"Delete this media?"}
        contentText={"This action cannot be undone."}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDeleteAlert}
        submitBtnTitle={"Delete"}
        submitBtnAction={handleConfirmClickedMediaDelete}
      />
      {/* delete dialog end */}
      {showMediaGalleryModal ? (        
        <div className="galleryModal" style={{ zIndex: 10 }}>
          <div className="gallery-modal-content">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="close-wrapper-category">
                  <CloseIcon onClick={handleCloseDel} />
                </div>
                <DialogTitle className="category-dialog-title">
                  {action == "line"
                    ? dataName?.prompt !== undefined
                      ? dataName?.prompt
                      : title
                    : title}
                </DialogTitle>
                <div {...getRootProps()} onClick={(e) => e?.stopPropagation()}>
                  <DialogContent
                    className="dialog-content-media"
                    sx={{ border: isDragActive && "2px dashed #4282e2" }}
                  >
                    <Grid container className="media-galley-container">
                      {!reOrderMedia && (
                        <div className="add-media-content">
                          {device.device.type !== "desktop" ? (
                            <>
                              <Typography
                                className="line_details__add_btn mt-0"
                                id="basic-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                              >
                                <AddCircleIcon />
                                <span className="line_details__add_text">
                                  Add new{" "}
                                </span>
                              </Typography>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseDel}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    !image
                                      ? editFileInput.current.click()
                                      : undefined
                                  }
                                >
                                  Upload from my device
                                </MenuItem>
                                <MenuItem
                                >
                                  Camera
                                </MenuItem>
                              </Menu>
                            </>
                          ) : (
                            <Typography
                              className="edit__media__add_btn mt-0"
                              onClick={() => editFileInput.current.click()}
                            >
                              <input
                                ref={editFileInput}
                                type="file"
                                accept="image/*,video/*"
                                onChange={handleAddMedia}
                                style={{ display: "none" }}
                                multiple
                              />
                              <div>
                                <AddCircleIcon />
                              </div>
                              <div
                                style={{
                                  textDecoration: "underline",
                                  color: "#4282e2",
                                }}
                              >
                                Click to add media
                              </div>
                              <div>or drag and drop</div>
                            </Typography>
                          )}
                        </div>
                      )}
                      {!reOrderMedia ? (
                        data?.map((image, index) =>
                          image.is_deleted !== 1 ? (
                            image.online === "online" &&
                            image.filetype === "image" ? (
                              <div className="galley_image" key={index}>
                                {deleteMedia && (
                                  <DeleteIcon
                                    className="media_delete_icon"
                                    onClick={() => deleteClickedMedia(image)}
                                  />
                                )}
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                    backgroundColor: "#FAFAFA",
                                  }}
                                  src={
                                    image.filetype === "image"
                                      ? image.is_portal == 1
                                        ? `${inspectionData?.bucket_url_2}${
                                            image.thumbnail !== null
                                              ? image.thumbnail
                                              : image.onlineUrl
                                          }`
                                        : `${inspectionData?.bucket_url}${
                                            image.thumbnail !== null
                                              ? image.thumbnail
                                              : image.onlineUrl
                                          }`
                                      : image.is_portal == 1
                                      ? `${inspectionData?.bucket_url_2}${
                                          image.thumbnail !== null
                                            ? image.thumbnail
                                            : image.onlineUrl
                                        }`
                                      : `${inspectionData?.bucket_url}${
                                          image.thumbnail !== null
                                            ? image.thumbnail
                                            : image.onlineUrl
                                        }`
                                  }
                                  alt="image"
                                ></img>
                                <p className="image_description_content">
                                  {image?.description !== null &&
                                  image?.description !== undefined &&
                                  image?.description?.length > 45 ? (
                                    <span onClick={() => handleCaption(image)}>
                                      {" "}
                                      {`${image.description?.substring(
                                        45,
                                        0
                                      )}...`}
                                    </span>
                                  ) : (
                                    <span onClick={() => handleCaption(image)}>
                                      {image.description}
                                    </span>
                                  )}
                                </p>
                                {image?.description === null ||
                                image?.description === undefined ||
                                image?.description === "" ? (
                                  <span
                                    className="image_description"
                                    onClick={() => handleCaption(image)}
                                  >
                                    <AddCircleIcon />
                                    {image?.description !== null &&
                                    image?.description !== undefined &&
                                    image?.description !== ""
                                      ? "Edit Caption"
                                      : "Caption"}{" "}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : image.online === "offline" ? (
                              ""
                            ) : (
                              <div className="galley_image" key={index}>
                                {deleteMedia && (
                                  <DeleteIcon
                                    className="media_delete_icon"
                                    onClick={() => deleteClickedMedia(image)}
                                  />
                                )}
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                    backgroundColor: "#FAFAFA",
                                  }}
                                  src={
                                    image.filetype === "image"
                                      ? image.is_portal == 1
                                        ? `${inspectionData?.bucket_url_2}${
                                            image.thumbnail !== null
                                              ? image.thumbnail
                                              : image.onlineUrl
                                          }`
                                        : `${inspectionData?.bucket_url}${
                                            image.thumbnail !== null
                                              ? image.thumbnail
                                              : image.onlineUrl
                                          }`
                                      : image.is_portal == 1
                                      ? `${inspectionData?.bucket_url_2}${
                                          image.thumbnail !== null
                                            ? image.thumbnail
                                            : image.onlineUrl
                                        }`
                                      : `${inspectionData?.bucket_url}${
                                          image.thumbnail !== null
                                            ? image.thumbnail
                                            : image.onlineUrl
                                        }`
                                  }
                                  alt="image"
                                ></img>
                                <PlayCircleIcon className="play_icon" />
                                <p className="image_description_content">
                                  {image?.description !== null &&
                                  image?.description !== undefined &&
                                  image?.description?.length > 45 ? (
                                    <span
                                      onClick={() => handleCaption(image)}
                                    >{`${image.description?.substring(
                                      45,
                                      0
                                    )}...`}</span>
                                  ) : (
                                    <span onClick={() => handleCaption(image)}>
                                      {image.description}
                                    </span>
                                  )}
                                </p>
                                {image?.description === null ||
                                image?.description === undefined ||
                                image?.description === "" ? (
                                  <span
                                    className="image_description"
                                    onClick={() => handleCaption(image)}
                                  >
                                    <AddCircleIcon />
                                    {image?.description !== null &&
                                    image?.description !== undefined &&
                                    image?.description !== ""
                                      ? "Edit Caption"
                                      : "Caption"}{" "}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            )
                          ) : (
                            ""
                          )
                        )
                      ) : (
                        <>
                          {
                            <div className="add-media-content">
                              {device.device.type !== "desktop" ? (
                                <>
                                  <Typography
                                    className="line_details__add_btn mt-0"
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick}
                                  >
                                    <AddCircleIcon />
                                    <span className="line_details__add_text">
                                      Add new{" "}
                                    </span>
                                  </Typography>
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseDel}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        !image
                                          ? editFileInput.current.click()
                                          : undefined
                                      }
                                    >
                                      Upload from my device
                                    </MenuItem>
                                    <MenuItem
                                    >
                                      Camera
                                    </MenuItem>
                                  </Menu>
                                </>
                              ) : (
                                <Typography
                                  className="edit__media__add_btn mt-0"
                                  onClick={() => editFileInput.current.click()}
                                >
                                  <input
                                    ref={editFileInput}
                                    type="file"
                                    accept="image/*,video/*"
                                    onChange={handleAddMedia}
                                    style={{ display: "none" }}
                                    multiple
                                  />
                                  <div>
                                    <AddCircleIcon />
                                  </div>
                                  <div
                                    style={{
                                      textDecoration: "underline",
                                      color: "#4282e2",
                                    }}
                                  >
                                    Click to add media
                                  </div>
                                  <div>or drag and drop</div>
                                </Typography>
                              )}
                            </div>
                          }
                          {mediaData?.map((image, index) =>
                            renderCard(image, index)
                          )}                          
                        </>
                      )}
                    </Grid>
                  </DialogContent>
                </div>
              </div>
              <div>
                <Divider style={{ width: "100%" }} />
                <DialogActions className="media-modal-action">
                  {!reOrderMedia && (
                    <Grid className="media-edit-action">
                      {data == undefined || data?.length == 0 ? (
                        ""
                      ) : (
                        <span onClick={() => setReOrderMedia(true)}>
                          <ReorderIcon />
                          Reorder media
                        </span>
                      )}
                      {data == undefined || data?.length == 0 ? (
                        ""
                      ) : (
                        <span onClick={() => setDeleteMedia(true)}>
                          <DeleteOutlineIcon />
                          Delete media
                        </span>
                      )}
                    </Grid>
                  )}

                  {reOrderMedia ? (
                    <Button
                      className="add-category-btn w-100"
                      onClick={() => {
                        setReOrderMedia(false);
                      }}
                    >
                      Done
                    </Button>
                  ) : deleteMedia ? (
                    <Button
                      className="add-category-btn w-100"
                      onClick={handleDeleteAction}
                    >
                      Done
                    </Button>
                  ) : (
                    <Button
                      className="add-category-btn w-100"
                      onClick={() => handleCloseDel()}
                    >
                      Save
                    </Button>
                  )}
                </DialogActions>
              </div>
            </div>
          </div>
        </div>      
      ) : (
        ""
      )}
    </>
  );
};

export default MediaGalleryModal;
